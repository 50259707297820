import { InteractionRequiredAuthError, PublicClientApplication, IPublicClientApplication } from "@azure/msal-browser";
import { msalConfig, tokenRequest, loginRequest } from "../../authConfig";
import { GetConfig } from "./Config";

let msalInstance: IPublicClientApplication | null = null;

export async function GetMsalInstance(): Promise<IPublicClientApplication> {
    if (!msalInstance) {
        const config = await GetConfig();
        msalConfig.auth.redirectUri = config.auth.redirectUrl;
        msalConfig.auth.clientId = config.auth.clientId;
        loginRequest.scopes = [config.auth.apiScope, ...loginRequest.scopes];
        tokenRequest.scopes = [config.auth.apiScope, ...tokenRequest.scopes];
        msalInstance = new PublicClientApplication(msalConfig);
        //await msalInstance.initialize();
    }
    console.log("MSAL Instance:", msalInstance);
    return msalInstance;
}

export async function BuildMsalInstance(altAuthority?: string): Promise<IPublicClientApplication> {
    const config = await GetConfig();
    msalConfig.auth.redirectUri = config.auth.redirectUrl;
    if (altAuthority) msalConfig.auth.authority = altAuthority;
    return new PublicClientApplication(msalConfig);
}

export const GetAccessToken = async () => {
    const instance = await GetMsalInstance();
    const accounts = instance.getAllAccounts();
    const account = accounts[0];
    const request = { ...tokenRequest, account };

    try {
        const token = await instance.acquireTokenSilent(request);
        return token?.accessToken;
    } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
            await instance.acquireTokenRedirect(request);
        } else {
            console.error(error);
        }
    }
}

export async function Logout() {
    const instance = await GetMsalInstance();
    await instance.logoutRedirect();
}
