import { IonButton, IonCard, IonCardContent, IonCol, IonGrid, IonInput, IonRow } from "@ionic/react";
import set from 'lodash/set';
import { useEffect, useState } from "react";
import { GetPersonAsync, UpdatePersonAsync, RyftPerson, UpdateRyftPersonDocumentAsync, UploadRyftPersonDocumentAsync } from "../../lib/data/Cards";
import Spinner from "../controls/Spinner";
import CardOnboardingDocumentList from "./DocumentList";
import CardOnboardingMissingDocs from "./MissingDocuments";
import { useParams } from "react-router";

export type CardOnboardingPersonProps = {
    personId: string,
    onClose: () => void,
};

interface ViewParams {
    userId: string,
};

export default function CardOnboardingPerson(props: CardOnboardingPersonProps) {
    const { userId } = useParams<ViewParams>();
    const [person, setPerson] = useState<RyftPerson>();

    async function saveChanges() {
        var result = await UpdatePersonAsync(userId!, props.personId!, person!);
        setPerson(result);
    }

    function setPersonValue(key: string, value: string) {
        const updated = set({ ...person }, key, value) as RyftPerson;
        setPerson(updated);
    }

    function fieldErrorMessage(key: string) {
        return <>
            {person?.verification?.requiredFields?.find(f => f.name === key) &&
                <p className="errorMessage">Required</p>
            }
            {person?.verification?.errors?.find(e => e.id === key) &&
                <p className="errorMessage">
                    {person?.verification.errors.find(e => e.id === key)?.description}
                </p>
            }
        </>;
    }

    useEffect(() => {
        GetPersonAsync(userId!, props.personId).then(person => setPerson(person));
    }, [userId, props.personId]);

    async function uploadDocument(documentType: string, fileFront: File, fileBack: File) {
        var response = await UploadRyftPersonDocumentAsync(
            userId!,
            props.personId,
            documentType,
            fileFront,
            fileBack
        );
        setPerson(response);
    }

    async function updateDocument(documentId: string, file: File) {
        var response = await UpdateRyftPersonDocumentAsync(
            userId!,
            props.personId,
            documentId,
            file
        );
        setPerson(response);
    }

    var mainContent = <>
        <IonCard>
            <IonCardContent>
                <IonGrid style={{ marginLeft: '-10px' }}>
                    <IonRow>
                        <IonCol sizeXs='8' sizeSm='7' sizeMd='8' sizeLg='8' sizeXl='8'><h1 className='detailsTitle'>Submitted Details</h1></IonCol>
                        <IonCol sizeXs='2' sizeSm='2' sizeMd='2' sizeLg='3' sizeXl='3'><IonButton onClick={() => props.onClose()}>Close</IonButton></IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol sizeXs='12' sizeSm='12' sizeMd='6' sizeLg='5' sizeXl='5'>
                            <p><strong>First Name: </strong></p>
                            <IonInput className="adminInputWhite"
                                value={person?.firstName}
                                onIonChange={val => setPersonValue('firstName', val.detail.value!)} />
                            {fieldErrorMessage('firstName')}
                            <br></br>

                            <p><strong>Middle Name: </strong></p>
                            <IonInput className="adminInputWhite"
                                value={person?.middleNames}
                                onIonChange={val => setPersonValue('middleNames', val.detail.value!)} />
                            {fieldErrorMessage('middleNames')}
                            <br></br>

                            <p><strong>Last Name: </strong></p>
                            <IonInput className="adminInputWhite"
                                value={person?.lastName}
                                onIonChange={val => setPersonValue('lastName', val.detail.value!)} />
                            {fieldErrorMessage('lastName')}
                            <br></br>

                            <p><strong>Date of Birth (yyyy-MM-dd): </strong></p>
                            <IonInput className="adminInputWhite"
                                value={person?.dateOfBirth}
                                onIonChange={val => setPersonValue('dateOfBirth', val.detail.value!)} />
                            {fieldErrorMessage('dateOfBirth')}
                            <br></br>

                            <p><strong>Gender (Male/Female): </strong></p>
                            <IonInput className="adminInputWhite"
                                value={person?.gender}
                                onIonChange={val => setPersonValue('gender', val.detail.value!)} />
                            {fieldErrorMessage('gender')}
                            <br></br>

                            {/*<p><strong>Nationality: </strong></p>*/}
                            {/*<IonInput className="adminInputWhite"*/}
                            {/*    value={person?.nationalities[0]}*/}
                            {/*    onIonChange={val => setPersonValue('nationalities', val.detail.value!)} />*/}
                            {/*{fieldErrorMessage('nationalities')}*/}

                            <IonButton
                                style={{ width: '270px', borderRadius: '12px', height: '30px', marginTop: '22px' }}
                                color="secondary"
                                size="small"
                                type="submit"
                                onClick={() => { saveChanges(); }}>
                                Update Details
                            </IonButton>

                        </IonCol>
                        <IonCol sizeXs='12' sizeSm='12' sizeMd='6' sizeLg='7' sizeXl='7'>
                            <p><strong>Address Line One: </strong></p>
                            <IonInput className="adminInputWhite"
                                value={person?.address.lineOne}
                                onIonChange={val => setPersonValue('address.lineOne', val.detail.value!)} />
                            {fieldErrorMessage('address.lineOne')}
                            <br></br>

                            <p><strong>Address Line Two: </strong></p>
                            <IonInput className="adminInputWhite"
                                value={person?.address.lineTwo}
                                onIonChange={val => setPersonValue('address.lineTwo', val.detail.value!)} />
                            {fieldErrorMessage('address.lineTwo')}
                            <br></br>

                            <p><strong>City: </strong></p>
                            <IonInput className="adminInputWhite"
                                value={person?.address.city}
                                onIonChange={val => setPersonValue('address.city', val.detail.value!)} />
                            {fieldErrorMessage('address.city')}
                            <br></br>

                            <p><strong>Region: </strong></p>
                            <IonInput className="adminInputWhite"
                                value={person?.address.region}
                                onIonChange={val => setPersonValue('address.region', val.detail.value!)} />
                            {fieldErrorMessage('address.region')}
                            <br></br>

                            <p><strong>Country: </strong></p>
                            <IonInput className="adminInputWhite"
                                value={person?.address.country}
                                onIonChange={val => setPersonValue('address.country', val.detail.value!)} />
                            {fieldErrorMessage('address.country')}
                            <br></br>

                            <p><strong>Postal Code: </strong></p>
                            <IonInput className="adminInputWhite"
                                value={person?.address.postalCode}
                                onIonChange={val => setPersonValue('address.postalCode', val.detail.value!)} />
                            {fieldErrorMessage('address.postalCode')}
                            <br></br>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonCardContent>
        </IonCard>

        <CardOnboardingDocumentList
            documents={person?.documents || []}
            onUpdateDocument={updateDocument} />

        <CardOnboardingMissingDocs
            missingDocuments={person?.verification.requiredDocuments || []}
            onUploadDocument={uploadDocument} />
    </>;

    return person ? mainContent : <Spinner />;
}