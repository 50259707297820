import { ApiGet, ApiPost } from "../util/Api";
import { AppendQuerystring } from "../util/Querystring";

export interface Partner {
    id: string;
    name: string;
    status: number;
}

export interface PartnerList {
    data: Partner[],
    continuation: string,
}

export enum PartnerStatus
{
    New = 10,
    Approved = 100,
}

export interface Invitation {
    id: string,
    status: InvitationStatus,
    created: Date,
    updated: Date,
    payload: InvitationPayload,
    association: InvitationAssociation
}

export interface InvitationPayload {
    merchantName?: string,
    merchantEmail?: string,
}

export interface InvitationList {
    data: Invitation[],
    continuation: string,
}

export enum InvitationStatus {
    New = 10,
    Sent = 20,
    Opened = 50,
    Completed = 100,
}

export interface InvitationAssociation {
    merchantId: string,
    accountId: string,
}

export type PartnerApiKeys = {
    clientId: string,
    clientSecret: string,
};

export async function GetPartnerByUser(userId: string) : Promise<Partner> {
    const response = await ApiGet(`users/${userId}/partner`); 
    if (response.status === 200) return response.data;
    throw response.data;
}

export async function ListPartnersAsync(name?: string, continuation?: string) : Promise<PartnerList> {
    var url = "partners";
    if (name && name > "") url = AppendQuerystring(url, "name", name);
    if (continuation && continuation > "") url = AppendQuerystring(url, "continuation", continuation);

    const response = await ApiGet(url);
    if (response.status === 200) return response.data;
    throw response.data;
}

export async function ApprovePartnerAsync(partnerId: string) {
    const response = await ApiPost(`partners/${partnerId}/approve`);
    return response.status === 200;
}

export async function ListPartnerInvitationsAsync(userId: string, searchText?: string, continuation?: string) {
    var url = `users/${userId}/partner/invitations`;
    if (continuation && continuation > "") url = AppendQuerystring(url, "continuation", continuation);
    if (searchText && searchText > "") url = AppendQuerystring(url, "search", searchText);
 
    const response = await ApiGet(url);
    if (response.status === 200) return response.data;
    throw response.data;
}

export async function GetPartnerInvitation(userId: string, invitationId: string): Promise<Invitation> {
    var response = await ApiGet(`users/${userId}/partner/invitations/${invitationId}`);
    if (response.status === 200) return response.data;
    throw response.data;
}

export async function GetPartnerApiKeys(partnerId: string): Promise<PartnerApiKeys> {
    var response = await ApiGet(`partners/${partnerId}/apikeys`);
    if (response.status.toString().startsWith('2')) return response.data;
    throw response.data;
}

export async function RecyclePartnerApiKeysAsync(partnerId: string) : Promise<PartnerApiKeys> {
    var response = await ApiPost(`partners/${partnerId}/recycleapikey`);
    if (response.status === 200) return response.data;
    throw response.data;
}