
import { createContext, useEffect, useState } from "react";
import Toast, { InactiveToast, ToastParams, ToastTypes } from "./Toast";

export interface ToastContextType {
    toastParams: ToastParams,
    error: (message: string) => void,
    success: (message: string) => void,
    close: () => void,
};

export const GlobalToastContext = createContext<ToastContextType>({
    toastParams: InactiveToast,
    error: () => { },
    success: () => { },
    close: () => { },
});

export const GlobalToastConsumer = GlobalToastContext.Consumer;

interface Props {
    children: React.ReactNode
}

export default function GlobalToastProvider({ children }: Props) {
    const [params, setParams] = useState<ToastParams>(InactiveToast);

    useEffect(() => {
        // ensure that when the modal closes the params are reset
        let existing = params.onDidDismiss;

        params.onDidDismiss = () => {
            setParams(InactiveToast);
            if (existing) existing();
        };
    }, [params]);

    if (!params) return <p>Loading...</p>;

    return <>
        <GlobalToastContext.Provider value={{
            toastParams: params,
            error: (message: string) => {
                setParams({ ...params, message, isOpen: true, type: ToastTypes.Error })
            },
            success: (message: string) => {
                setParams({ ...params, message, isOpen: true, type: ToastTypes.Success })
            },
            close: () => setParams({ ...params, isOpen: false }),
        }}>
            {children}
            <Toast {...params} />
        </GlobalToastContext.Provider>
    </>;
}