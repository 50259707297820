import { IonButton, IonCard, IonCardContent, IonContent, IonModal, IonSelect, IonSelectOption } from "@ionic/react";
import { useRef, useState } from "react";
import FileUploadControl from "../../components/FileUpload";
import { RyftRequiredDocument } from "../../lib/data/Cards";


export type CardOnboardingMissingDocsProps = {
    missingDocuments: RyftRequiredDocument[],
    onUploadDocument: (documentType: string, fileFront: File, fileBack: File) => Promise<void>,
};

export default function CardOnboardingMissingDocs(props: CardOnboardingMissingDocsProps) {

    const [showFileUpload, setShowFileUpload] = useState(false);
    const fileFront = useRef<File>();
    const fileBack = useRef<File>();
    const documentType = useRef<string>();

    function resetFiles() {
        documentType.current = undefined;
        fileFront.current = undefined;
        fileBack.current = undefined;
    }

    async function showUploadModal() {
        setShowFileUpload(true);
    }

    function modalDismissed() {
        resetFiles();
        setShowFileUpload(false);
    }

    async function downloadDocument(fileName: string) {

    }

    return <>
        <IonCard>
            <IonCardContent>
                <div>
                    <h3 className='detailsTitle'>Missing Documents</h3>
                    
                    <table style={{ width: '100%' }}>
                        <thead>
                            <tr className='tableRowSpacing'>
                                <th style={{ textAlign: 'left' }}><b>Category</b></th>
                                <th style={{ textAlign: 'left' }}><b>Type</b></th>
                                <th style={{ textAlign: 'left' }}><b>Quantity</b></th>
                                <th><IonButton style={{ width: '90px', borderRadius: '12px', height: '30px', alignItem: 'left' }}
                        color="secondary"
                        size="small"
                        type="submit"
                        onClick={() => showUploadModal()}>
                        Upload
                    </IonButton></th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.missingDocuments && props.missingDocuments.map((doc, index) => (
                                <tr className='tableRowSpacing' key={"missing" + index}>
                                    <td className='adminBlueDataText'>{doc.category}</td>
                                    <td className='adminBlueDataText'>{doc.types.join(', ')}</td>
                                    <td className='adminBlueDataText'>{doc.quantity}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </IonCardContent>
        </IonCard>

        <IonModal isOpen={showFileUpload} onDidDismiss={modalDismissed}>
            <IonContent className="ion-padding">
                <h3 className="detailsTitle">Upload File</h3>
                <p style={{color:'#737373', fontWeight:'bold' }}>File Type:</p>
                <IonSelect onIonChange={(e) => documentType.current = e.detail.value}>
                    <IonSelectOption value="ArticlesOfIncorporation">Articles Of Incorporation</IonSelectOption>
                    <IonSelectOption value="BankStatement">Bank Statement</IonSelectOption>
                    <IonSelectOption value="BusinessRegistration">Business Registration</IonSelectOption>
                    <IonSelectOption value="CreditCardStatement">Credit Card Statement</IonSelectOption>
                    <IonSelectOption value="DriversLicense">Driver's License</IonSelectOption>
                    <IonSelectOption value="LetterOfAuthorization">Letter Of Authorization</IonSelectOption>
                    <IonSelectOption value="NationalId">National ID</IonSelectOption>
                    <IonSelectOption value="OfficialGovernmentLetter">Official Government Letter</IonSelectOption>
                    <IonSelectOption value="OperatingAgreement">Operating Agreement</IonSelectOption>
                    <IonSelectOption value="Passport">Passport</IonSelectOption>
                    <IonSelectOption value="PropertyTaxAssessment">Property Tax Assessment</IonSelectOption>
                    <IonSelectOption value="TaxReturn">Tax Return</IonSelectOption>
                    <IonSelectOption value="UtilityBill">Utility Bill</IonSelectOption>
                </IonSelect>
                <p style={{color:'#737373', fontWeight:'bold' }}>Front:</p>
                <FileUploadControl
                    onFileChange={(file: File) => new Promise((resolve) => { fileFront.current = file; resolve(""); })}
                    onFileSelect={(fileName: string) => downloadDocument(fileName)} />
                <p style={{color:'#737373', fontWeight:'bold' }}>Back:</p>
                <FileUploadControl
                    onFileChange={(file: File) => new Promise((resolve) => { fileBack.current = file; resolve(""); })}
                    onFileSelect={(fileName: string) => downloadDocument(fileName)} />
                <IonButton style={{ width: '90px', borderRadius: '12px', height: '30px', alignItem: 'left' }}
                    color="secondary"
                    size="small"
                    type="submit"
                    onClick={() => props
                        .onUploadDocument(documentType.current!, fileFront.current!, fileBack.current!)
                        .then(() => modalDismissed())}>
                    Upload &#187;
                </IonButton>
            </IonContent>
        </IonModal>
    </>;
}