import { InfiniteScrollCustomEvent, IonButton, IonButtons, IonCard, IonCardContent, IonCol, IonContent, IonDatetime, IonGrid, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonInput, IonItem, IonList, IonMenuButton, IonModal, IonPage, IonRow, IonSelect, IonSelectOption, IonSpinner, IonToolbar } from '@ionic/react';
import { format } from 'date-fns';
import { addDays, addMonths, endOfMonth, endOfWeek, startOfMonth, startOfWeek } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { GetUserAsync, Invoice, ListInvoices, User } from '../lib';
import './InvoiceHistory.css';

interface ViewParams {
    userId: string,
};

export default function InvoiceHistory() {
    const { userId } = useParams<ViewParams>();
    const [disableInfiniteScroll, setdisableInfiniteScroll] = useState<boolean>(false);
    const [continuation, setContinuation] = useState<string>();
    const [status, setStatus] = useState<string | undefined>();
    const [invoiceNumber, setInvoiceNumber] = useState<string | undefined>();
    const [start, setStart] = useState<string | undefined>();
    const [end, setEnd] = useState<string | undefined>();
    const [dateStrings, setDateStrings] = useState<string[] | []>();
    const [showDateRangeModel, setShowDateRangeModel] = useState<boolean>(false);
    const [showInvoiceHistorySpinner, setshowInvoiceHistorySpinner] = useState<boolean>(false);
    const [rangeEnable, setRangeEnable] = useState<boolean>(false);
    const history = useHistory();
    const formatDate = (dateString: string) => format(new Date(dateString), 'dd.MM.yyyy');
    const lockApiCalls = useRef(false);
    const [invoices, setInvoices] = useState<Invoice[]>([]);
    const [user, setUser] = useState<User>();

    var currentDateStrings = [] as string[];

    const getHistoryAsync = async (ev?: InfiniteScrollCustomEvent) => {
        try {
            if (userId) {
                setshowInvoiceHistorySpinner(true);

                let continuationToken = continuation;
                if (!ev) {
                    continuationToken = undefined;
                }
                var response = await ListInvoices(userId, continuationToken!, 50, status, invoiceNumber, start, end);
                setshowInvoiceHistorySpinner(false);

                if ((response?.data?.length ?? 0) === 0) {
                    if (response.continuation === null) setInvoices([]);
                    return;
                }
                setInvoices(continuationToken ? ([...invoices!, ...response.data]) : response.data);
                setContinuation(response.continuation!);
                if (invoices.length >= 150) setdisableInfiniteScroll(true);
                if (ev) ev.target.complete();
            }
        }
        finally {
            lockApiCalls.current = false;
        }
    }

    useEffect(() => {
        // two levels of preventing subsequent queries needed here -
        // one for if a call has gone out but the state is not yet populated (lockApiCalls)
        // and another if all the api calls have gone out but states are in process of being populated (pageStateIsEmpty)
        if (lockApiCalls.current === true) return;
        lockApiCalls.current = true;
        getHistoryAsync();
    }, [dateStrings]);

    // changing the user id clears the list and fetches the user again
    // whether by url or user action against the context i.e.from search click
    useEffect(() => {
        if (userId) {
            GetUserAsync(userId).then(user => setUser(user!));
            setInvoices([]);
        }
    }, [userId]);

    // once the user has been fetched, get the new list 
    useEffect(() => {
        if (userId) {
            getHistoryAsync();
        }
    }, [user]);

    useEffect(() => {
        if (start && end && start == end) {
            setDateStrings([start]);
        }
    }, [start, end])

    const contentRef = useRef<HTMLIonContentElement | null>(null);

    const onClickDateRangeModel = () => {
        setShowDateRangeModel(false);
    };

    const closeDateRangeModel = () => {
        setShowDateRangeModel(false);
    }

    const setDatesFromOption = (dateCode: string) => {
        setRangeEnable(false);
        switch (dateCode) {
            case "1": // Today
                setStart(format(new Date(), "yyyy-MM-dd"));
                setEnd(format(new Date(), "yyyy-MM-dd"));
                break;
            case "2": // Yesterday
                setStart(format(addDays(new Date(), -1), "yyyy-MM-dd"));
                setEnd(format(addDays(new Date(), -1), "yyyy-MM-dd"));
                break;
            case "3": // This Week
                setStart(format(startOfWeek(new Date(), { weekStartsOn: 1 }), "yyyy-MM-dd"));
                setEnd(format(endOfWeek(new Date(), { weekStartsOn: 1 }), "yyyy-MM-dd"));
                break;
            case "4": // Last Week
                setStart(format(addDays(startOfWeek(new Date(), { weekStartsOn: 1 }), -7), "yyyy-MM-dd"));
                setEnd(format(endOfWeek(addDays(startOfWeek(new Date(), { weekStartsOn: 1 }), -7), { weekStartsOn: 1 }), "yyyy-MM-dd"));
                break;
            case "5": // This Month
                setStart(format(startOfMonth(new Date()), "yyyy-MM-dd"));
                setEnd(format(endOfMonth(new Date()), "yyyy-MM-dd"));
                break;
            case "6": // Last Month
                setStart(format(addMonths(startOfMonth(new Date()), -1), "yyyy-MM-dd"));
                setEnd(format(endOfMonth(addMonths(startOfMonth(new Date()), -1)), "yyyy-MM-dd"));
                break;
            case "7": // Last 7 Days
                setStart(format(addDays(new Date(), -6), "yyyy-MM-dd"));
                setEnd(format(new Date(), "yyyy-MM-dd"));
                break;
            case "8": // Last 30 Days
                setStart(format(addDays(new Date(), -29), "yyyy-MM-dd"));
                setEnd(format(new Date(), "yyyy-MM-dd"));
                break;
            case "9": // Custom Range
                setRangeEnable(true);
                break;

            default: // All
                setStart(undefined);
                setEnd(undefined);
                break;
        }
    };

    const onClickSearchInvoiceHistory = () => {
        getHistoryAsync();
    };
    const onInvoiceNumberChanged = (val: CustomEvent) => {
        setInvoiceNumber(val.detail.value);

    };

    const onDateChanged = (val: CustomEvent) => {

        dateChange(val.detail.value);
        if (Array.isArray(val.detail.value)) {

            if (val.detail.value.length >= 1) {
                setStart(val.detail.value[0],);
                setEnd(val.detail.value[val.detail.value.length - 1]);
            }
        }
    };

    const onClickCancelStatusSelect = () => {
        setStatus(undefined);
    };

    const onClickShowDateRangeModel = () => {
        if (rangeEnable) {
            setShowDateRangeModel(true);
        }
    };

    const dateChange = (selectedDate: string[]) => {

        if (selectedDate === undefined) {
            //setStart(undefined);
            //setEnd(undefined);
            currentDateStrings = [];
            setDateStrings(undefined);
            return;
        }

        if (selectedDate !== undefined && !Array.isArray(selectedDate)) return;

        var localDateStrings = selectedDate as string[];
        // Detect changes (prevents an infinite loop).
        if (currentDateStrings === localDateStrings) { return; }

        // Make sure we have at least two dates (otherwise nothing to do).
        if (!(localDateStrings && localDateStrings?.length > 1)) { return; }

        // Sort the dates so the earliest one is first.
        localDateStrings.sort((a, b) => {
            if (a > b) { return 1; }
            else if (a < b) { return -1; }
            else { return 0; }
        });

        // Revert to single selection (if required).
        if (dateStrings && dateStrings?.length > 2) {
            for (const d of dateStrings) {
                if (localDateStrings.indexOf(d) === -1) {
                    // Make sure this is not the first or last date (those are handled natively).
                    currentDateStrings = [d];
                    setDateStrings([d]);
                    return;
                    // No need to continue.
                    break;
                }
            }

            for (const d of localDateStrings) {
                if (dateStrings.indexOf(d as never) === -1) {
                    currentDateStrings = [d];
                    setDateStrings([d]);
                    return;
                    // No need to continue.
                    break;
                }
            }
        }

        // Store the new value.
        const newValue = [];

        // Add all dates between the first and second dates.
        for (let d = new Date(localDateStrings[0]); d <= new Date(localDateStrings[localDateStrings.length - 1]); d.setDate(d.getDate() + 1)) {
            newValue.push(format(d, "yyyy-MM-dd"));
        }

        // Update the values at the end so the UI gets updated only once.
        currentDateStrings = newValue;
        setDateStrings(newValue);
        // Ref https://github.com/ionic-team/ionic-framework/issues/23572#issuecomment-1227543345
    }
    const navigateToInvoiceDetail = (userId: string, invoiceId: string) => {

        history.push(`/InvoiceDetails/${userId}/${invoiceId}`);
    }

    const InvoiceStatusIcon = (invoiceStatus: string | undefined) => {
        if ((invoiceStatus === "Pending") || (invoiceStatus === "Processing") || (invoiceStatus === 'Started')) {
            return <IonImg src="/assets/icon/hourglassIcon.svg" />
        }
        else if ((invoiceStatus === "Success") || (invoiceStatus === "Completed")) {
            return <IonImg src="/assets/icon/greenTickIcon.svg" />
        }
        else {
            return <IonImg src="/assets/icon/redCrossIcon.svg" />
        }

    }

    const InvoiceStatusColor = (invoice: string | undefined) => {


        if ((invoice === 'Pending') || (invoice === 'Processing') || (invoice === 'Started')) {
            return 'invoiceStatusPendingOrInProgress'
        }
        else if ((invoice === 'Success') || (invoice === 'Completed')) {
            return 'invoiceStatusSuccess'
        }
        else {
            return 'invoiceStatusFailed'
        }


    }

    return <IonPage>
        <IonToolbar >
            <IonButtons slot="start">
                <IonMenuButton></IonMenuButton>
            </IonButtons>
            <IonGrid>

                <IonRow className='subscriptionTitleRows'>
                    <IonCol size='auto'>
                        <h1 id='invoicesMainTitle'>Invoices -</h1>
                    </IonCol>
                    <IonCol size='auto'>
                        <h1 id='invoicesUsersName'>{user?.firstName}, {user?.lastName}</h1>

                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonToolbar>
        <IonContent className='paymentHistoryBlock' ref={contentRef} scrollEvents={true}>

            <IonRow>
                <IonCol sizeXl='8' sizeLg='8' sizeMd='8' sizeSm='8' sizeXs='7'>
                    <IonInput

                        placeholder='Invoice Number'
                        className="descriptionInput"
                        onIonChange={onInvoiceNumberChanged}
                        value={invoiceNumber}>
                    </IonInput>
                </IonCol>
                <IonCol sizeXl='1' sizeLg='1' sizeMd='1' sizeSm='3' sizeXs='3'>
                    <IonButton
                        className="searchButton"
                        shape="round"
                        color="secondary"
                        size="small"
                        onClick={onClickSearchInvoiceHistory}>Search
                    </IonButton>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeXs='6' sizeSm='6' sizeMd='2' sizeLg='3' sizeXl='3'> <div className="filterInvoicesDiv">
                    <IonList>
                        <IonItem lines="none">
                            <IonSelect className='invoicesFilter' placeholder="Status" interface="popover"
                                onIonChange={(e) => setStatus(e.detail.value)}
                                onIonCancel={onClickCancelStatusSelect}
                                value={status}>
                                <IonSelectOption value="">All</IonSelectOption>
                                <IonSelectOption value="100">Complete</IonSelectOption>
                                <IonSelectOption value="10">Pending</IonSelectOption>
                                <IonSelectOption value="200">Error</IonSelectOption>
                            </IonSelect>
                        </IonItem>
                    </IonList>
                    <IonList>
                        <IonItem lines="none">
                            <IonSelect className='invoicesFilter' placeholder="Date"
                                interface="popover"
                                onIonChange={(e) => setDatesFromOption(e.detail.value)}>
                                <IonSelectOption value="0">All</IonSelectOption>
                                <IonSelectOption value="1">Today</IonSelectOption>
                                <IonSelectOption value="2">Yesterday</IonSelectOption>
                                <IonSelectOption value="3">This Week</IonSelectOption>
                                <IonSelectOption value="4">Last Week</IonSelectOption>
                                <IonSelectOption value="5">This Month</IonSelectOption>
                                <IonSelectOption value="6">Last Month</IonSelectOption>
                                <IonSelectOption value="7">Last 7 Days</IonSelectOption>
                                <IonSelectOption value="8">Last 30 Days</IonSelectOption>
                                <IonSelectOption value="9">Custom Range</IonSelectOption>
                            </IonSelect>
                        </IonItem>
                    </IonList>
                </div>
                </IonCol>


            </IonRow>
            <IonRow>
                <IonCol sizeXs='1' sizeSm='1' sizeMd='1' sizeLg='2' sizeXl='3' ></IonCol>
                <IonCol sizeXs='5' sizeSm='5' sizeMd='5' sizeLg='4' sizeXl='3'>
                    <IonInput value={start ? format(new Date(start), "dd-MM-yyyy") : ''}
                        placeholder="From"
                        hidden={!rangeEnable}
                        disabled={!rangeEnable}
                        onClick={onClickShowDateRangeModel}
                        className="datePicker">
                    </IonInput>
                </IonCol>

                <IonCol sizeXs='5' sizeSm='5' sizeMd='5' sizeLg='4' sizeXl='3'>
                    <IonInput value={end ? format(new Date(end), "dd-MM-yyyy") : ''}
                        placeholder="To"
                        hidden={!rangeEnable}
                        disabled={!rangeEnable}
                        onClick={onClickShowDateRangeModel}
                        className="datePicker">
                    </IonInput>
                </IonCol>
                <IonCol sizeXs='1' sizeSm='1' sizeMd='1' sizeLg='2' sizeXl='3'></IonCol>

            </IonRow>
            <div className='ion-text-center'>{showInvoiceHistorySpinner && <IonSpinner></IonSpinner>}</div>
            <IonCard className='invoiceGrid'>
                <IonCardContent>
                    <IonGrid fixed>
                        <IonList>
                            <IonRow>
                                <IonCol><h1 id='invoicesSubTitle'>Invoices</h1></IonCol>
                            </IonRow>

                            <IonRow className='invoiceItemTitle'>
                                <IonCol> Number</IonCol>
                                <IonCol>Date</IonCol>
                                <IonCol>Amount</IonCol>
                                <IonCol>Status</IonCol>

                            </IonRow>
                            {userId && invoices?.map((invoice, index) => {
                                return (

                                    <IonRow onClick={() => navigateToInvoiceDetail(userId, invoice.id)} className='invoiceItem' id={`invoice_${invoice.id}`} key={invoice.id}>
                                        <IonCol> {invoice.number}</IonCol>
                                        <IonCol>{formatDate(invoice.created.toString())}</IonCol>
                                        <IonCol>{invoice.amount.toFixed(2)}</IonCol>
                                        <IonCol > <div className='invoiceIconDiv'>{InvoiceStatusIcon(invoice.status)}</div><div className={InvoiceStatusColor(invoice.status)}>{invoice.status}</div></IonCol>

                                    </IonRow>
                                );
                            })}
                            {/* <IonItem>
                        <IonNote slot='start'> Number</IonNote>
                        <IonNote slot='end'>Date</IonNote>
                        <IonNote slot='end'>Amount</IonNote>
                        <IonNote slot='end'>Status</IonNote>
                        <IonNote slot='end'></IonNote>
                    </IonItem>
                    {invoices?.map((invoice, index) => {
                        return (

                            <IonItem className='invoiceItem' id={`invoice_${invoice.id}`} key={invoice.id}>
                                <IonNote slot='start'> {invoice.number}</IonNote>
                                <IonNote slot='end'>{formatDate(invoice.created.toString())}</IonNote>
                                <IonNote slot='end'>{invoice.amount}</IonNote>
                                <IonNote slot='end'>{invoice.status}</IonNote>
                                <a style={{ color: '#003466' }} slot='end' onClick={(e) => navigateToInvoiceDetail(e, userId,invoice.id)}>View</a>
                            </IonItem>
                        );
                    })} */}

                            <IonInfiniteScroll threshold="200px" onIonInfinite={getHistoryAsync} disabled={disableInfiniteScroll}>
                                <IonInfiniteScrollContent loadingSpinner="bubbles" loadingText="Loading...">
                                </IonInfiniteScrollContent>
                            </IonInfiniteScroll>
                        </IonList>
                    </IonGrid>
                </IonCardContent>
            </IonCard>
            <div className='paymentHistoryBlock'></div>
        </IonContent>
        {/* <InvoiceDetailsModal invoice={selectedInvoice} onClosed={onClickCancelModel} /> */}

        <IonModal handle={false} backdropDismiss={false} canDismiss={true} isOpen={showDateRangeModel} onDidDismiss={closeDateRangeModel} breakpoints={[0, 0.68]}
            initialBreakpoint={0.68} backdropBreakpoint={0.2}  >
            <IonContent className='dateTimeContent'>
                <br></br>
                <IonDatetime
                    className='datePicker'
                    multiple={true}
                    presentation="date"
                    value={dateStrings}
                    onIonChange={onDateChanged}>
                </IonDatetime>
                <IonButton style={{ width: '120px' }} size="small" shape="round" color="secondary" onClick={() => onClickDateRangeModel()}>Select</IonButton>
            </IonContent>
        </IonModal>
    </IonPage>;
};