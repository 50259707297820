import { IonButton, IonContent, IonDatetime, IonInput, IonModal } from "@ionic/react";
import { format, parseISO } from "date-fns";
import { useEffect, useState } from "react";

export interface DatePickerProps {
    value: Date | Date[] | null,
    onChange: (value: Date | Date[] | null) => void,
    mode: DatePickerMode,
}

export enum DatePickerMode {
    SingleImmediate,
    SingleConfirm,
    Multiple,
    Range,
}

export default function DatePicker({ value, onChange, mode }: DatePickerProps) {

    const [showModal, setShowModal] = useState(false);
    const [dateStrings, setDateStrings] = useState<string[]>([]);
    const [selectedDates, setSelectedDates] = useState<Date[]>([]);
    const shouldShowButton = mode === DatePickerMode.SingleConfirm || mode === DatePickerMode.Multiple || mode === DatePickerMode.Range;
    const [displayText, setDisplayText] = useState<string>('');

    const updateDisplayText = (dates: Date[]) => {
        if (dates.length === 0) {
            setDisplayText('');
        } else if (dates.length === 1) {
            setDisplayText(format(dates[0], "yyyy-MM-dd")); // Single date format
        } else if (mode === DatePickerMode.Multiple) {
            // Multiple dates format: comma-separated
            setDisplayText(dates.map(date => format(date, "yyyy-MM-dd")).join(', '));
        } else {
            // Range format: start - end
            setDisplayText(`${format(dates[0], "yyyy-MM-dd")} - ${format(dates[dates.length - 1], "yyyy-MM-dd")}`);
        }
    };

    useEffect(() => {
        // Call updateDisplayText when selectedDates changes
        updateDisplayText(selectedDates);
    }, [selectedDates]);

    useEffect(() => {
        var newStrings: string[] = [];

        if (value instanceof Date) {
            newStrings = ([format(value, "yyyy-MM-dd")]);
        } else if (Array.isArray(value)) {
            newStrings = (value.map(date => format(date, "yyyy-MM-dd")));
        } else {
            newStrings = ([]);
        }

        if (dateStrings != newStrings) setDateStrings(newStrings);
    }, [value]);

    const arraysEqual = (a: Date[], b: Date[]) => {
        if (a === b) return true;
        if (a == null || b == null) return false;
        if (a.length !== b.length) return false;

        for (var i = 0; i < a.length; ++i) {
            if (a[i].getTime() !== b[i].getTime()) return false;
        }
        return true;
    };

    const dateChange = (selectedDate: string | string[] | undefined | null) => {
        var newDates = [] as Date[];

        if (typeof selectedDate === 'string') {
            const date = parseISO(selectedDate);
            newDates = [date];

            if (mode === DatePickerMode.SingleImmediate) {
                onChange(date); // Fire onChange immediately for SingleImmediate mode
                setShowModal(false);
            }
        } else if (Array.isArray(selectedDate)) {
            const dateObjects = selectedDate.map(dateStr => parseISO(dateStr));

            if (mode === DatePickerMode.Range) {
                // Sort the dates to ensure they are in the correct order
                dateObjects.sort((a, b) => a.getTime() - b.getTime());

                // Generate all dates in the range
                const rangeDates = generateDateRange(dateObjects[0], dateObjects[dateObjects.length - 1]);
                newDates = rangeDates;
            } else {
                newDates = dateObjects;
            }
        } else {
            newDates = [];
        }

        if (!arraysEqual(selectedDates, newDates)) {
            setSelectedDates(newDates);
        }
    };

    const generateDateRange = (startDate: Date, endDate: Date): Date[] => {
        const dates = [];
        for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
            dates.push(new Date(d));
        }
        return dates;
    };

    const onClickSelect = () => {
        switch (mode) {
            case DatePickerMode.SingleConfirm:
                if (selectedDates.length === 1) {
                    onChange(selectedDates[0]);
                }
                break;
            case DatePickerMode.Multiple:
                onChange(selectedDates);
                break;
            case DatePickerMode.Range:
                if (selectedDates.length === 2) {
                    onChange(selectedDates);
                }
                break;
        }
    };

    const modal = <>
        {showModal && <IonModal handle={false}
            backdropDismiss={false}
            canDismiss={true}
            isOpen={true}
            onDidDismiss={_ => setShowModal(false)}
            breakpoints={[0, 0.68]}
            initialBreakpoint={0.68}
            backdropBreakpoint={0.2}>
            <IonContent className='dateTimeContent'>
                <br></br>
                <IonDatetime
                    className='datePicker'
                    multiple={mode === DatePickerMode.Multiple || mode === DatePickerMode.Range}
                    presentation="date"
                    value={dateStrings}
                    onIonChange={val => dateChange(val.detail.value)}>
                </IonDatetime>
                {shouldShowButton && (
                    <IonButton style={{ width: '120px' }}
                        size="small"
                        shape="round"
                        color="secondary"
                        onClick={() => onClickSelect()}>Select</IonButton>
                )}
            </IonContent>
        </IonModal>}
    </>;

    return <>
        <IonInput value={displayText}
            placeholder="Select"
            disabled={false}
            onClick={_ => setShowModal(true)}
            className="datePicker">
        </IonInput>

        {modal}
    </>;
}