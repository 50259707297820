import { IonButton, IonCard, IonCardContent, IonCol, IonGrid, IonRow } from "@ionic/react";
import { useEffect, useState } from "react";
import { GetPersonsAsync, RyftPerson } from "../../lib/data/Cards";
import Spinner from "../controls/Spinner";
import CardOnboardingPerson from "./Person";
import { useParams } from "react-router";

interface ViewParams {
    userId: string,
};

export default function CardOnboardingPersonList() {
    const { userId } = useParams<ViewParams>();
    const [persons, setPersons] = useState<RyftPerson[]>();
    const [personId, setPersonId] = useState<string>();

    useEffect(() => {
        GetPersonsAsync(userId!).then(data => setPersons(data));
    }, [userId]);

    return personId ? <CardOnboardingPerson personId={personId} onClose={() => setPersonId(undefined)} />
        : <>
            <IonCard>
                <IonCardContent>
                    <IonGrid style={{ marginLeft: '-10px' }}>
                        <IonRow>
                            <IonCol sizeXs='8' sizeSm='7' sizeMd='8' sizeLg='8' sizeXl='8'>
                                <h3 className='detailsTitle'>Persons</h3>
                            </IonCol>
                            <IonCol sizeXs='2' sizeSm='2' sizeMd='2' sizeLg='3' sizeXl='3'>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <table style={{ width: '100%', textAlign: 'left' }}>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Verification Status</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {persons ? persons.map(person =>
                                            <tr key={person.id}>
                                                <th>{person.firstName} {person.lastName}</th>
                                                <td>{person.verification.status}</td>
                                                <td>{person.businessRoles.join(' & ')}</td>
                                                <td><IonButton onClick={() => setPersonId(person.id)}>View</IonButton></td>
                                            </tr>
                                        ) : <tr><td colSpan={3}><Spinner /></td></tr>}
                                    </tbody>
                                </table>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonCardContent>
            </IonCard >
        </>;
}