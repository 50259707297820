import { useState, useEffect } from "react";
import { GetMerchantPartnerAsync, Merchant, ListPartnersAsync, Partner, UpdateMerchantPartnerAsync } from "../../../lib";
import { IonButton, IonHeader, IonModal, IonTitle, IonToolbar, IonContent, IonSelect, IonSelectOption } from "@ionic/react";
import { Spinner } from "../../controls";
import ConfirmationButton from "../../../components/ConfirmationButton";

export type MerchantPartnerToolsProps = {
    accountId: string;
    merchant: Merchant;
}

export function MerchantPartnerTools(props: MerchantPartnerToolsProps) {
    const [partner, setPartner] = useState<Partner | null | undefined>(null);
    const [allPartners, setAllPartners] = useState<Partner[] | null>(null);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (props.merchant.invitationReference) GetMerchantPartnerAsync(props.accountId).then(setPartner);
        else setPartner(null);
    }, [props.accountId]);

    useEffect(() => {
        if (showModal) {
            setAllPartners(null); // Reset partners when modal opens
            ListPartnersAsync().then((partnerList) => setAllPartners(partnerList.data));
        }
    }, [showModal]);

    if (partner === undefined) return <Spinner />;

    const changePartnerModal = (
        <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Change partner</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                {allPartners === null ? (
                    <Spinner />
                ) : (
                    <>
                        <IonSelect placeholder="Select a partner"
                            onIonChange={(e) => {
                                const selectedPartner = allPartners.find(p => p.id === e.detail.value);
                                setPartner(selectedPartner || null);
                            }}>
                            {allPartners?.map((p) => (
                                <IonSelectOption key={p.id} value={p.id}>{p.name}</IonSelectOption>
                            ))}
                        </IonSelect>
                        <ConfirmationButton
                            buttonLabel="Change partner"
                            alertHeader="Change partner"
                            alertMessage="Are you sure you want to change the partner for this merchant?"
                            onConfirm={() => {
                                if (partner) UpdateMerchantPartnerAsync(props.accountId, partner.id).then(() => setShowModal(false));
                            }}
                        />
                    </>
                )}
            </IonContent>
        </IonModal>
    );

    return <>
        {partner === null ? <>No partner defined</> : <>Current partner: {partner.name}</>}
        <br />
        <br />
        <IonButton onClick={() => setShowModal(true)}>Change partner</IonButton>
        {changePartnerModal}
    </>;
}