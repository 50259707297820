import { format } from 'date-fns';
import { GetBankHolidays } from './Config';

export const formatDateStringToLocal = (utcDate: string) => {
    const localDate = new Date(utcDate);
    return format(localDate, 'yyyy-MM-dd HH:mm');
};

export const formatDateToLocal = (utcDate: Date) => {
    return format(utcDate, 'yyyy-MM-dd HH:mm');
};

export const FormatDate = (date: Date , dateFormat: string = "d MMM - hh:mmaaa") :string => {
    return format(new Date(date),dateFormat );
}

export function addDays(date: Date, days: number): Date {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + days);
    return newDate;
}

const BH_CACHE_KEY = "bankHolidays";
const BH_CACHE_EXPIRATION_MS = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

// Function to retrieve bank holidays
async function retrieveBankHolidays(): Promise<Date[]> {
    const now = new Date().getTime();
    const cachedData = localStorage.getItem(BH_CACHE_KEY);

    if (cachedData) {
        const { expiration, data } = JSON.parse(cachedData);

        // Check if the cached data is still valid
        if (now < expiration) {
            return data.map((dateString: string) => new Date(dateString));
        }
    }

    // Fetch new data if there's no valid cache
    const freshData = await GetBankHolidays();
    const expirationTime = now + BH_CACHE_EXPIRATION_MS;

    // Cache the new data with expiration time
    localStorage.setItem(BH_CACHE_KEY, JSON.stringify({ expiration: expirationTime, data: freshData }));

    return freshData;
}