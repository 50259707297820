import { ApiGet, ApiPost, ApiPut, MerchantCharges } from "../";

export interface RyftAccount {
    id: string;
    type: string;
    status: string;
    actionsRequired: RyftActionRequired[];
    frozen: boolean;
    email: string;
    onboardingFlow: string;
    entityType: string;
    business: RyftBusiness;
    individual: RyftIndividual;
    verification: RyftVerification;
    metadata: RyftAccountMetadata;
    settings: RyftSettings;
    createdTimestamp: number;
}

export interface RyftActionRequired {
    action: string;
    description: string;
}

export interface RyftBusiness {
    name: string;
    type: string;
    registrationNumber: string;
    registrationDate: Date;
    registeredAddress: RyftAddress;
    contactEmail: string;
    tradingName: string;
    tradingAddress: RyftAddress;
    tradingCountries: string[];
    websiteUrl: string;
    documents: RyftDocument[];
}

export interface RyftAddress {
    lineOne: string;
    lineTwo: string;
    city: string;
    country: string;
    postalCode: string;
    region: string;
}

export interface RyftDocument {
    type: string;
    category: string;
    front: string;
    back: string;
    status: string;
    invalidReason: string;
    assignedTimestamp: number;
    lastUpdatedTimestamp: number;
    country: string;
}

export interface RyftIndividual {
    firstName: string;
    middleNames: string;
    lastName: string;
    email: string;
    dateOfBirth: string;
    countryOfBirth: string;
    gender: string;
    nationalities: string[];
    address: RyftAddress;
    phoneNumber: string;
    documents: RyftDocument[];
}

export interface RyftPerson extends RyftIndividual {
    id: string,
    businessRoles: string[];
    verification: RyftVerification;
    documents: RyftDocument[];
    metadata: RyftPersonMetadata;
}

export interface RyftVerification {
    status: string;
    requiredFields: RyftRequiredField[];
    requiredDocuments: RyftRequiredDocument[];
    errors: RyftError[];
    persons: RyftPersons;
}

export interface RyftRequiredField {
    name: string;
}

export interface RyftRequiredDocument {
    category: string;
    types: string[];
    quantity: number;
}

export interface RyftError {
    code: string;
    id: string;
    description: string;
}

export interface RyftPersons {
    status: string;
    required: RyftRequiredPerson[];
}

export interface RyftRequiredPerson {
    role: string;
    quantity: number;
}

export interface RyftAccountMetadata {
    merchantId: string;
}

export interface RyftPersonMetadata {
    merchantId: string;
    beneficiaryId: string;
    directorId: string;
    businessContactId: string;
}

export interface RyftSettings {
    payouts: RyftPayouts;
}

export interface RyftPayouts {
    schedule: RyftPayoutSchedule;
}

export interface RyftPayoutSchedule {
    type: string;
}

export interface CardsModel {
    ryftAccountId: string;
    verificationTimestamp: Date;
    charges: MerchantCharges | undefined;
}

export async function GetCardsModel(userId: string): Promise<CardsModel> {
    var response = await ApiGet(`users/${userId}/cards`);
    if (response.status === 200) return response.data;
    else throw response.data;
}

export async function GetRyftOnboardingAsync(userId: string): Promise<RyftAccount> {
    var response = await ApiGet(`users/${userId}/cards/onboarding`);
    if (response.status === 200) return response.data;
    else throw response.data;
}

export async function PostRyftTestTransferAsync(userId: string, amount: number): Promise<boolean> {
    var response = await ApiPost(`users/${userId}/cards/testtransfer`, { amount });
    return response.status === 200;
}

export async function PostRyftTestPayoutAsync(userId: string, amount: number): Promise<boolean> {
    var response = await ApiPost(`users/${userId}/cards/testpayout`, { amount });
    return response.status === 200;
}

export async function PostRyftOnboardingAsync(userId: string, onboarding: RyftAccount): Promise<RyftAccount> {
    var response = await ApiPost(`users/${userId}/cards/onboarding`, onboarding);
    return response.data;
}

export async function UpdateRyftOnboardingAsync(userId: string): Promise<string> {
    var response = await ApiPost(`users/${userId}/cards/onboarding/update`, {});
    return response.data;
}

export async function RyftBusinessVerifyAsync(userId: string): Promise<RyftAccount> {
    var response = await ApiPost(`users/${userId}/cards/onboarding/verify`, {});
    return response.data;
}

export async function UpdateRyftOnboardingDocumentAsync(userId: string, documentId: string, file: File): Promise<RyftAccount> {
    return await UpdateDocumentAsync(`users/${userId}/cards/onboarding/documents/${documentId}`, file!);
}

export async function UploadRyftOnboardingDocumentAsync(userId: string, type: string, front: File, back: File): Promise<RyftAccount> {
    return await UploadDocumentAsync(`users/${userId}/cards/onboarding/documents`, type, front, back);
}

export async function GetPersonAsync(userId: string, personId: string): Promise<RyftPerson> {
    var response = await ApiGet(`users/${userId}/cards/onboarding/persons/${personId}`);
    return response.data;
}

export async function CreatePersonAsync(
    userId: string,
    ownerId: string,
    directorId: string,
    isBusinessContact: boolean): Promise<RyftPerson> {
    var response = await ApiPost(
        `users/${userId}/cards/onboarding/persons/`, {
        ownerId,
        directorId,
        isBusinessContact,
    });
    return response.data;
}

export async function UpdatePersonAsync(userId: string, personId: string, person: RyftPerson): Promise<RyftPerson> {
    var response = await ApiPut(`users/${userId}/cards/onboarding/persons/${personId}`, person);
    return response.data;
}

export async function GetPersonsAsync(userId: string): Promise<RyftPerson[]> {
    var response = await ApiGet(`users/${userId}/cards/onboarding/persons`);
    return response.data;
}

export async function UpdateRyftPersonDocumentAsync(
    userId: string,
    personId: string,
    documentId: string,
    file: File): Promise<RyftPerson> {
    return await UpdateDocumentAsync(`users/${userId}/cards/onboarding/persons/${personId}/documents/${documentId}`, file);
}

export async function UploadRyftPersonDocumentAsync(
    userId: string,
    personId: string,
    type: string,
    front: File,
    back: File): Promise<RyftPerson> {
    return await UploadDocumentAsync(
        `users/${userId}/cards/onboarding/persons/${personId}/documents`,
        type,
        front,
        back);
}

export async function PutCardsModel(userId: string, model: CardsModel) {
    var response = await ApiPut(`users/${userId}/cards`, model);
    return response.status === 200;
}

async function UpdateDocumentAsync(url: string, file: File) {
    const formData = new FormData();
    if (file) formData.append('file', file, file.name);
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    };
    var response = await ApiPut(url, formData, config);
    return response.status === 200 ? response.data : null;
}

async function UploadDocumentAsync(url: string, type: string, front: File, back: File) {
    const formData = new FormData();
    if (front) formData.append('front', front, front.name);
    if (back) formData.append('back', back, back.name);
    if (type) formData.append('type', type);
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    };
    var response = await ApiPost(url, formData, config);
    return response.status === 200 ? response.data : null;
}