import axios from "axios";
import { parseISO } from "date-fns";

export type ApiConfig = {
    auth: {
        redirectUrl: string,
        clientId: string,
        apiScope: string,
    },
    isDevelopment: boolean,
    isStaging: boolean,
    isProduction: boolean,
    isSandbox: boolean,
    qrCode: {
        baseUrl: string,
    },
    
};

const axiosInstance = axios.create({
    baseURL: '/api',
});

let config: ApiConfig | null = null;
let configPromise: Promise<ApiConfig> | null = null;

export const GetConfig = async (): Promise<ApiConfig> => {
    if (!config && !configPromise) {
        configPromise = (async (): Promise<ApiConfig> => {
            const response = await axiosInstance.get('config');
            config = response.data;
            return config!;
        })();
    }
    if (config) {
        return config;
    }
    if (configPromise) {
        return configPromise;
    }
    throw new Error('Configuration not initialized');
}

export const GetBankHolidays = async () : Promise<Date[]> => {
    var result = await axiosInstance.get<string[]>('bankholidays');
    return result.data.map(b => parseISO(b));
}