import { IonButton, IonCard, IonCardContent, IonContent, IonModal } from "@ionic/react";
import { useRef, useState } from "react";
import FileUploadControl from "../../components/FileUpload";
import { RyftDocument } from "../../lib/data/Cards";


export type CardOnboardingDocumentProps = {
    documents: RyftDocument[],
    onUpdateDocument: (documentId: string, file: File) => Promise<void>,
};

export default function CardOnboardingDocumentList(props: CardOnboardingDocumentProps) {

    const [showFileUpdate, setShowFileUpdate] = useState(false);
    const fileRef = useRef<File>();
    const documentId = useRef<string>();

    async function showUpdateModal(docId: string) {
        documentId.current = docId;
        setShowFileUpdate(true);
    }

    function resetFiles() {
        documentId.current = undefined;
        fileRef.current = undefined;
    }

    function modalDismissed() {
        resetFiles();
        setShowFileUpdate(false);
    }

    async function downloadDocument(fileName: string) {

    }

    return <>
        <IonCard style={{ marginBottom: '25px' }}>
            <IonCardContent>
                <div>
                    <h3 className='detailsTitle'>Document List</h3>
                    <table style={{ width: '100%' }}>
                        <thead>
                            <tr className='tableRowSpacing'>
                                <th style={{ textAlign: 'left' }}><b>Document Type</b></th>
                                <th style={{ textAlign: 'left' }}>Status</th>
                                <th style={{ textAlign: 'left' }}>Invalid reason</th>
                                <th style={{ textAlign: 'left' }}>Upload New Document</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.documents && props.documents.map((doc, index) =>
                                <>
                                    <tr className='tableRowSpacing' key={"doc" + index}>
                                        <td className='adminBlueDataText'>{doc.type}</td>
                                        <td className='adminBlueDataText'>{doc.status}</td>
                                        <td className='adminBlueDataText'>
                                            {doc.invalidReason}
                                        </td>
                                        <td>
                                            <IonButton style={{ width: '90px', borderRadius: '12px', height: '30px', alignItem: 'left' }}
                                                color="secondary"
                                                size="small"
                                                type="submit"
                                                onClick={() => showUpdateModal(doc.front || doc.back)}>Update &#187;</IonButton>
                                        </td>
                                    </tr>
                                </>
                            )}
                        </tbody>
                    </table>
                </div>
            </IonCardContent>
        </IonCard>

        <IonModal isOpen={showFileUpdate} onDidDismiss={modalDismissed}>
            <IonContent className="ion-padding">
                <div>
                    <h3 className="detailsTitle">Update file</h3>
                    <div style={{ marginTop: '40px' }}>
                        <FileUploadControl
                            onFileChange={(file: File) => new Promise((resolve) => { fileRef.current = file; resolve(""); })}
                            onFileSelect={(fileName: string) => downloadDocument(fileName)} />
                    </div>
                    <div style={{ marginTop: '40px' }}>
                        <IonButton style={{ width: '90px', borderRadius: '12px', height: '30px', alignItem: 'left' }}
                            color="secondary"
                            size="small"
                            type="submit"
                            onClick={() => props.onUpdateDocument(documentId.current!, fileRef.current!).then(() => modalDismissed())}>Upload &#187;</IonButton>
                    </div>
                </div>

            </IonContent>
        </IonModal>
    </>;
}