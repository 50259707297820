import { ApiGet } from "../util/Api";

export interface HttpAuditLog {
    timestamp: Date,
    payload: string,
    statusCode: number,
    isResponse: boolean,
    url: string,
    method: string,
    webhook: boolean,
}

export interface HttpAuditLogResponse {
    data: HttpAuditLog[],
    continuation: string,
}

export async function LoadHttpAuditLogs(entityId: string, continuation: string | undefined): Promise<HttpAuditLogResponse> {
    var url = `/audit/http/${entityId}`;
    if (continuation && continuation > '') url += `?continuation=${encodeURIComponent(continuation)}`;
    var response = await ApiGet(url);
    if (response.status === 200) return response.data;
    else throw response.data;
}