import { IonButton, IonCol, IonContent, IonModal, IonRow } from "@ionic/react";
import React, { useState } from "react";
import { HttpAuditLog, LoadHttpAuditLogs } from "../../lib/data/Audit";

export interface HttpAuditButtonProps {
    entityId: string,
    entityName?: string,
};

export default function HttpAuditButton(props: HttpAuditButtonProps) {
    const [logs, setLogs] = useState<HttpAuditLog[]>();
    const [continuation, setContinuation] = useState<string | undefined>();

    async function onClickViewLogs() {
        let response = await LoadHttpAuditLogs(props.entityId, continuation);
        setLogs(response.data);
        setContinuation(response.continuation);
    }

    async function modalClosed() {
        setLogs(undefined);
        setContinuation(undefined);
    }

    const button = <IonButton onClick={onClickViewLogs}>
        View Recent {props.entityName} Api Calls
    </IonButton>

    const modal = <>
        {logs && <IonModal  className="auditModal" isOpen={true} onIonModalDidDismiss={modalClosed}>
            <IonContent className="ion-padding">
                {logs.map((item, index) => {
                    return <React.Fragment key={index}>
                        <IonRow style={{marginTop:'20px', color:'#003466'}}>
                            <IonCol><u>{item.timestamp}</u></IonCol>
                        </IonRow>
                        <IonRow style={{ color:'#003466', marginLeft:'5px', marginTop:'10px', marginBottom:'10px'}}>
                           <b> Data Flow: {item.isResponse == true ? 'In' : 'Out'}</b>
                        </IonRow>
                        <IonRow style={{color:'#003466'}}>
                            <IonCol><b>{item.statusCode}</b></IonCol>
                            <IonCol><b>{item.method}</b></IonCol>
                            <IonCol><b>{item.url}</b></IonCol>
                        </IonRow>
                        <IonRow style={{marginTop:'10px', paddingBottom:'10px', color:'#003466'}} className='invoiceBorder'>
                            <IonCol style={{lineHeight:'30px'}}>{item.payload}</IonCol>
                        </IonRow>
                    </React.Fragment>
                })}
            </IonContent>
        </IonModal>}
    </>;

    return <>
        {button}
        {modal}
    </>;
}