import { useState } from "react";
import DatePickerRange, { DateRange } from "../DatePickerRange";
import ConfirmationButton from "../../../components/ConfirmationButton";

interface CreateInvoiceControlProps {
    title: string;
    buttonLabel: string;
    alertHeader: string;
    onConfirm: (dateRange: DateRange) => void;
    isLoading: boolean;
}

export default function CreateInvoiceControl({ 
    title = "Create Invoice", 
    buttonLabel = "Create Invoice", 
    alertHeader = "Create Invoice", 
    onConfirm, 
    isLoading 
}: CreateInvoiceControlProps) {
    const [dateRange, setDateRange] = useState<DateRange>({ startDate: null, endDate: null });

    const alertMessage = dateRange.startDate && dateRange.endDate 
        ? "Warning: this will create an invoice for all uninvoiced items in the selected date range" 
        : "Warning: this will create an invoice for all uninvoiced items in the previous calendar month";

    return <>
        <h2 style={{ fontWeight: "bold", marginBottom: "20px" }}>{title}</h2>

        <DatePickerRange 
            onChange={(dateRange) => setDateRange(dateRange)} 
            startDate={dateRange.startDate} 
            endDate={dateRange.endDate} 
        />
        
        <ConfirmationButton
            buttonLabel={buttonLabel}
            alertHeader={alertHeader}
            alertMessage={alertMessage}
            onConfirm={() => onConfirm(dateRange)}
            isLoading={isLoading}
        />
    </>
}