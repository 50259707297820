import { ApiGet, ApiPost } from "..";

export type Page = {
    id: string,
    merchantId: string,
    status: string,
    amount: number,
    description: string,
    created: string,
};

export type Payment = {
    id: string,
    status: string,
    amount: number,
    description: string,
    customerDetails: string,
    created: Date,
    clientDateCreated: string,
    invoiceId: string,
    reference: string,
    orgName?: string, 
    merchantId: string,
    payerName?: string,
    paymentMethod?: PaymentMethod,
    message: string,
};
export enum PaymentMethod {
    NotAttempted = 0,
    OpenBanking = 10,
    Card = 20,
};
export type PaymentList = {
    data: Page[],
    continuation: string | undefined,
};

export type ListPayment = {
    data: Payment[],
    continuation: string | undefined,
};

export type PaymentSearch = {
    payLink: string
};

export const GetPaymentHistoryAsync = async (accountId: string, continuation: string): Promise<PaymentList> => {
    var url = `users/${accountId}/payments`;
    if (continuation > '') url += `&continuation=${continuation}`;
    var response = await ApiGet(url);

    if (response.status === 200) {
        return response?.data;
    }

    return { data: new Array<Page>(), continuation: undefined };
}

export const FindPaymentsAsync = async (search: PaymentSearch, continuation: string | undefined = undefined): Promise<PaymentList> => {
    if (search.payLink <= '') return { data: new Array<Page>(), continuation: undefined };

    var url = `payments?`;
    if (search.payLink > '') url += `payLink=${search.payLink}&`;
    if (continuation) url += `continuation=${continuation}`;

    var response = await ApiGet(url);

    if (response.status === 200) {
        return response?.data;
    }

    return { data: new Array<Page>(), continuation: undefined };
}

export const GetPaymentAsync = async (paymentId: string): Promise<Payment | null> => {
    var response = await ApiGet(`/payments/${paymentId}`);

    if (response.status === 200) {
        return response?.data;
    }

    return null;
}

export const ListPayments = async (
    userId: string,
    continuation: string, 
    pageSize?: number, 
    status?: string, 
    customerDetails?: string,
    description?: string, 
    start?: string, 
    end?: string , 
    subMerchantName?: string,
    type?: string): Promise<ListPayment> => {

    var url = `users/${userId}/payments?`;
    if (userId > '') url += `userId=${userId}&`;
    if (continuation > '') url += `continuation=${continuation}&`;
    if (pageSize) url += `pageSize=${pageSize}&`;
    if (status) url += `status=${status}&`;
    if (customerDetails) url += `customerDetails=${customerDetails}`
    if (description) url += `description=${description}&`;
    if (start) url += `start=${start}&`;
    if (end) url += `end=${end}&`;
    if (subMerchantName) url += `subMerchantName=${subMerchantName}&`;
    if (type) url += `type=${type}`;
    var response = await ApiGet(url);

    if (response.status === 200) {
        return response?.data;
    }

    return { data: new Array<Payment>(), continuation: undefined };
    // do something?
}