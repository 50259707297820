import { ApiGet } from "../util/Api";

export interface StandingOrder {
    id: string,
    created: Date,
    clientDateCreated: Date,
    payload: StandingOrderPayload,
    status: StandingOrderStatus,
    message: string,
}

export interface StandingOrderList {
    data: StandingOrder[],
    continuation: string,
}

export interface StandingOrderTransaction {
    id: string,
    paymentDate: Date,
    type: StandingOrderTransactionType,
}

export interface StandingOrderTransactionList {
    data: StandingOrderTransaction[],
    continuation: string,
}

export interface StandingOrderPayload {
    frequency?: StandingOrderFrequency,
    startDate?: Date,
    endDate?: Date,
    amount?: number,
    payerName?: string,
    payerBusinessName?: string,
    description?: string,
    numberOfPayments?: number,
}

export enum StandingOrderFrequency {
    Daily = 1,
    Weekly,
    TwiceWeekly,
    Monthly,
    TwiceMonthly,
    Quarterly,
    SemiAnnually,
    Annually
}

export enum StandingOrderStatus {
    Unknown = 0, // dummy/empty/should not be used
    Pending = 10, // created but no further action has taken place
    Started = 20, // payer has begun the process
    Processing = 30, // payer has returned from Token
    Failed = 50, // failed to create the standing order
    Abandoned = 70, // the process never completed (not yet used but should be)
    Active = 100, // the standing order is processing payments
    MissedPayment = 150, // we have become aware a scheduled payment was not made
    Cancelled = 170, // we have become aware the standing order will never complete
    Completed = 200, // the standing order successfully completed all its payments
}

export enum StandingOrderTransactionType {
    Verified = 10,
    Unverified = 20,
    Unmonitored = 30,
}

export const ListStandingOrders = async (
    accountId: string, 
    continuation?: string,
    searchText?: string,
    frequency?: StandingOrderFrequency): Promise<StandingOrderList> => {

    var url = `users/${accountId}/standingOrders?`;
    if (frequency) url += `frequency=${frequency}&`;
    if (searchText) url += `searchText=${searchText}&`;
    if (continuation) url += `continuation=${encodeURIComponent(continuation)}&`;

    var response = await ApiGet(url);

    if (response.status === 200) {
        return response?.data;
    } else {
        throw (response?.data);
    }
}

export const RetrieveStandingOrder = async (accountId: string, standingOrderId: string): Promise<StandingOrder> => {
    var response = await ApiGet(`users/${accountId}/standingorders/${standingOrderId}`);

    if (response.status === 200) {
        return response?.data;
    } else {
        throw (response?.data);
    }
}

export const ListStandingOrderTransactions = async (accountId: string, standingOrderId: string, continuation?: string): Promise<StandingOrderTransactionList> => {
    var url = `users/${accountId}/standingorders/${standingOrderId}/transactions`;
    if (continuation) url += `&continuation=${encodeURIComponent(continuation)}`;
    var response = await ApiGet(url);

    if (response.status === 200) {
        return response?.data;
    } else {
        throw (response?.data);
    }
}