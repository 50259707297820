import {
    IonButtons, IonCard, IonCardContent, IonCol,
    IonContent, IonGrid, IonHeader, IonInput, IonMenuButton, IonPage, IonRow, IonToolbar
} from "@ionic/react";
import set from 'lodash/set';
import { useEffect, useRef, useState } from "react";
import ConfirmationButton from "../../components/ConfirmationButton";
import { ApiConfig, GetConfig, GetUserAsync, User } from "../../lib";
import {
    CardsModel, GetCardsModel, GetRyftOnboardingAsync, PostRyftOnboardingAsync,
    PostRyftTestPayoutAsync,
    PostRyftTestTransferAsync,
    PutCardsModel,
    RyftAccount, RyftBusinessVerifyAsync, UpdateRyftOnboardingAsync,
    UpdateRyftOnboardingDocumentAsync, UploadRyftOnboardingDocumentAsync
} from "../../lib/data/Cards";
import CardOnboardingBusiness from "./Business";
import CardOnboardingDocumentList from "./DocumentList";
import CardOnboardingMissingDocs from "./MissingDocuments";
import CardOnboardingMissingPersons from "./MissingPersons";
import CardOnboardingPersonList from "./PersonList";
import CardOnboardingSoleTrader from "./SoleTrader";
import { useParams } from "react-router";

interface ViewParams {
    userId: string,
};

export default function CardOnboarding() {
    const [config, setConfig] = useState<ApiConfig>();
    const { userId } = useParams<ViewParams>();
    const [ryftAccount, setRyftAccount] = useState<RyftAccount>();
    const [user, setUser] = useState<User>();
    const lockApiCalls = useRef(false);
    const [status, setStatus] = useState<string>();
    const [cardsModel, setCardsModel] = useState<CardsModel>();
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [isVerifying, setIsVerifying] = useState<boolean>(false);
    const [payoutAmount, setPayoutAmount] = useState(0);
    const [transferAmount, setTransferAmount] = useState(0);
    const [updateResponse, setUpdateResponse] = useState<string>();

    async function getOnboarding() {
        try {
            var onboarding = await GetRyftOnboardingAsync(userId!);
            setRyftAccount(onboarding);
        } catch {
            setRyftAccount(undefined);
        }
    }

    async function getCardsModel() {
        try {
            var model = await GetCardsModel(userId!);
            setCardsModel(model);

            if (model.verificationTimestamp) setStatus("Approved");
            else if (model.ryftAccountId) setStatus("In progress");
            else setStatus("Not requested");
        } catch {
            setStatus('Not available');
        }
    }

    useEffect(() => { GetConfig().then(c => setConfig(c)); }, []);

    useEffect(() => {
        async function getDataAsync() {
            try {
                if (userId) {
                    lockApiCalls.current = true;

                    const promises = [
                        GetUserAsync(userId).then(u => setUser(u!)),
                        getOnboarding(),
                        getCardsModel(),
                    ];

                    await Promise.all(promises);
                }
            }
            finally {
                lockApiCalls.current = false;
            }
        }

        getDataAsync();
    }, [userId]);

    function setOnboardingValue(key: string, value: string) {
        const updated = set({ ...ryftAccount }, key, value) as RyftAccount;
        setRyftAccount(updated);
    }

    function fieldErrorMessage(key: string) {
        return <>
            {ryftAccount?.verification?.requiredFields?.find(f => f.name === key) &&
                <p className="errorMessage">Required</p>
            }
            {ryftAccount?.verification?.errors?.find(e => e.id === key) &&
                <p className="errorMessage">
                    {ryftAccount?.verification.errors.find(e => e.id === key)?.description}
                </p>
            }
        </>;
    }

    async function saveChanges() {
        var result = await PostRyftOnboardingAsync(userId!, ryftAccount!);
        if (result) setRyftAccount(result);
    }

    async function uploadDocument(documentType: string, fileFront: File, fileBack: File) {
        var response = await UploadRyftOnboardingDocumentAsync(
            userId!,
            documentType,
            fileFront,
            fileBack
        );
        setRyftAccount(response);
    }

    async function updateDocument(documentId: string, file: File) {
        var response = await UpdateRyftOnboardingDocumentAsync(
            userId!,
            documentId,
            file
        );
        setRyftAccount(response);
    }

    async function onClickUpdate() {
        setIsUpdating(true);
        var response = await UpdateRyftOnboardingAsync(userId!);
        setUpdateResponse(response);
        await getCardsModel();
        await getOnboarding();
        setIsUpdating(false);
    }

    async function onClickVerify() {
        setIsVerifying(true);
        await RyftBusinessVerifyAsync(userId!);
        await getCardsModel();
        await getOnboarding();
        setIsVerifying(false);
    }

    async function onClickTestPayout() {
        setIsUpdating(true);
        await PostRyftTestPayoutAsync(userId!, payoutAmount);
        await getCardsModel();
        await getOnboarding();
        setIsUpdating(false);
    }

    async function onClickTestTransfer() {
        setIsUpdating(true);
        await PostRyftTestTransferAsync(userId!, transferAmount);
        await getCardsModel();
        await getOnboarding();
        setIsUpdating(false);
    }

    async function onClickUpdateCardFee() {
        setIsUpdating(true);
        await PutCardsModel(userId!, cardsModel!);
        await getCardsModel();
        await getOnboarding();
        setIsUpdating(false);
    }

    return <IonPage>
        <IonHeader className='ion-no-border'>
            <IonToolbar style={{ marginBottom: '25px' }}>
                <IonButtons slot="start">
                    <IonMenuButton></IonMenuButton>
                </IonButtons>
                <IonGrid>
                    <IonRow className='onboardingTitleRows'>
                        <IonCol size='auto'>
                            <h1 id='onboardingTitle'>Card Onboarding - {user?.firstName}, {user?.lastName} </h1>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonToolbar>
        </IonHeader>
        <IonContent>

            <IonCard style={{ marginBottom: '25px' }}>
                <IonCardContent>
                    <h2 className='adminSubHeadingBlue'>Onboarding Status</h2>

                    {!cardsModel?.verificationTimestamp &&
                    <div>
                        <ConfirmationButton
                            buttonLabel="Update"
                            alertHeader="Update Card Onboarding"
                            alertMessage="This will call Ryft, get the latest version of the onboarding data and attempt any automatic updates already programmed."
                            onConfirm={onClickUpdate}
                            isLoading={isUpdating} />
                            {updateResponse && updateResponse > '' && <p>Last Update Response: {updateResponse}</p>}
                    </div>
                    }

                    {ryftAccount?.business &&
                        ryftAccount.verification.status !== "Verified" &&
                        ryftAccount.verification.persons.status === "Verified" &&
                        ryftAccount.verification.requiredDocuments.length === 0 &&
                        ryftAccount.verification.requiredFields.length === 0 &&
                        ryftAccount.verification.errors.length === 0 &&
                        !cardsModel?.verificationTimestamp &&
                        <ConfirmationButton
                            buttonLabel="Verify"
                            alertHeader="Send Verification Request"
                            alertMessage="This will send the final verification request to Ryft, so all data for the business and persons must be complete."
                            onConfirm={onClickVerify}
                            isLoading={isVerifying} />
                    }

                    <div>
                        <table style={{ width: '50%' }}>
                            <tbody>
                                <tr className='tableRowSpacing'>
                                    <td><b>Status: </b></td>
                                    <td>{status}</td>
                                </tr>
                                <tr className='tableRowSpacing'>
                                    <td><b>Approval Date:</b></td>
                                    <td className='adminBlueDataText'>
                                        {cardsModel?.verificationTimestamp}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div><br />

                    <div>
                        <strong>Set card processing fee:</strong><br />
                        <IonInput className="adminInputWhite"
                            value={((cardsModel?.charges?.percentageCharge ?? 0) * 100).toFixed(2)}
                            type="number"
                            onIonChange={val => setCardsModel({ 
                                ...cardsModel!, 
                                charges: {
                                    percentageCharge: Number((parseFloat(val.detail.value!) / 100).toFixed(4))
                                }
                            })} />
                        <ConfirmationButton
                            buttonLabel="Set Card Fee"
                            alertHeader="Set Card Fee"
                            alertMessage="This will change the amount the merchant is charged for every future card transaction."
                            onConfirm={onClickUpdateCardFee}
                            isLoading={isUpdating} />
                    </div><br />

                    {!(config?.isProduction) &&
                        ryftAccount &&
                        ryftAccount.verification.status === "NotRequired" && <>
                            <div>
                                <p>
                                    <strong>Transfer test balance:</strong><br />
                                    To see the current balance log in to Ryft's dashboard.<br />
                                    Sole traders need to payout over &pound;2500 to trigger verification, Ltd co &pound;1.<br />
                                    Minimum payout is &pound;100.
                                </p>

                                <p> Transfer from master account:

                                    &pound;<IonInput className="adminInputWhite"
                                        value={transferAmount}
                                        type="number"
                                        onIonChange={val => setTransferAmount(parseFloat(val.detail.value!))} />

                                    <ConfirmationButton
                                        buttonLabel="Create Transfer"
                                        alertHeader="Create Transfer"
                                        alertMessage="This will call Ryft to transfer funds from our master account to the merchant account."
                                        onConfirm={onClickTestTransfer}
                                        isLoading={isUpdating} />
                                </p>

                                <p>
                                    Payout from merchant account:

                                    &pound;<IonInput className="adminInputWhite"
                                        value={payoutAmount}
                                        type="number"
                                        onIonChange={val => setPayoutAmount(parseFloat(val.detail.value!))} />

                                    <ConfirmationButton
                                        buttonLabel="Create Payout"
                                        alertHeader="Create Payout"
                                        alertMessage="This will call Ryft to create test payout to remove the funds."
                                        onConfirm={onClickTestPayout}
                                        isLoading={isUpdating} />
                                </p>
                            </div>
                        </>}
                </IonCardContent>
            </IonCard>

            {ryftAccount?.individual && <>
                <CardOnboardingSoleTrader
                    onboarding={ryftAccount}
                    fieldErrorMessage={fieldErrorMessage}
                    saveChanges={saveChanges}
                    setOnboardingValue={setOnboardingValue} />
            </>}

            {ryftAccount?.business && <>
                <CardOnboardingBusiness
                    onboarding={ryftAccount}
                    fieldErrorMessage={fieldErrorMessage}
                    saveChanges={saveChanges}
                    setOnboardingValue={setOnboardingValue} />

                <CardOnboardingPersonList />

                <CardOnboardingMissingPersons
                    missingPersons={ryftAccount.verification.persons.required}
                    onNewPerson={(person) => getOnboarding()} />
            </>}

            {ryftAccount && <>
                <CardOnboardingDocumentList
                    documents={ryftAccount.individual?.documents || ryftAccount.business?.documents || []}
                    onUpdateDocument={updateDocument} />

                <CardOnboardingMissingDocs
                    missingDocuments={ryftAccount.verification?.requiredDocuments || []}
                    onUploadDocument={uploadDocument} />
            </>}

        </IonContent>

    </IonPage>;
};