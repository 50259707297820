import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel, IonList, IonMenuButton, IonModal, IonPage, IonRouterLink, IonRow, IonToggle, IonToolbar, IonText, IonTitle
} from '@ionic/react';
import { format } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import ConfirmationButton from '../../components/ConfirmationButton';
import {
    Account,
    ApiKey,
    GetAccountAsync,
    GetApiKeyAsync,
    GetMerchantAsync,
    GetUserAsync,
    InvoiceItem,
    Merchant,
    MerchantSettings,
    PreviewInvoiceAsync,
    RaiseInvoiceAsync,
    RecyclePrivateApiKey,
    SubMerchant,
    SubMerchantAccount,
    UpdateMerchantSettingsAsync,
    UpdateSubmerchantAsync,
    User,
    SubMerchantEmailAddress,
    UpdateMerchantEmailAddressesAsync
} from '../../lib';
import HttpAuditButton from '../controls/HttpAuditButton';
import { useParams } from 'react-router';
import CreateInvoiceControl from '../controls/invoicing/CreateInvoiceControl';
import { DateRange } from '../controls/DatePickerRange';
import PageSpinner from '../controls/PageSpinner';
import { MerchantPartnerTools } from './controls/MerchantPartnerTools';

type ToolsPageState = {
    user: User | null,
    account: Account | null,
    merchant: Merchant | null,
    apiKey: ApiKey | null,
};

interface ViewParams {
    userId: string,
};

export default function MerchantToolsPage() {
    const lockApiCalls = useRef(false);
    const { userId } = useParams<ViewParams>();

    const [isCreatingInvoice, setIsCreatingInvoice] = useState(false);

    const [isRecyclingKey, setIsRecylingKey] = useState(false);
    const [pageState, setPageState] = useState<ToolsPageState>({
        user: null,
        account: null,
        merchant: null,
        apiKey: null,
    });

    const [selectedSubmerchant, setSelectedSubmerchant] = useState<SubMerchant>();
    const [selectedSubMerchantAccount, setSelectedSubMerchantAccount] = useState<SubMerchantAccount>();
    const [newRole, setNewRole] = useState<string>();
    const [selectedEmailAddress, setSelectedEmailAddress] = useState<SubMerchantEmailAddress>();
    const [newTemplate, setNewTemplate] = useState<string>();
    const [newEmailAddress, setNewEmailAddress] = useState<string>('');

    function updatePageState(name: keyof ToolsPageState, value: ToolsPageState[keyof ToolsPageState]) {
        setPageState((prevState) => {
            if (!prevState) return { [name]: value } as ToolsPageState;
            return { ...prevState, [name]: value };
        });
    };

    function isPageStateEmpty() {
        return pageState.user === null &&
            pageState.account === null &&
            pageState.merchant === null &&
            pageState.apiKey === null;
    }

    async function getMerchantAndApiKey() {
        return GetMerchantAsync(userId!).then(merchant => {
            if (merchant?.apiKeyId) {
                GetApiKeyAsync(merchant?.apiKeyId).then(apiKey => {
                    updatePageState('apiKey', apiKey);
                    setIsRecylingKey(false);
                });
            }
            updatePageState('merchant', merchant);
            setSettings(merchant?.settings);
            setInitialSettings(merchant?.settings);
        });
    }

    async function getUserAsync() {
        try {
            if (userId) {
                const promises = [
                    GetUserAsync(userId).then(user => updatePageState('user', user)),
                    GetAccountAsync(userId).then(account => updatePageState('account', account)),
                    getMerchantAndApiKey(),
                ];

                await Promise.all(promises);
            }
        }
        finally {
            lockApiCalls.current = false;
        }
    }

    useEffect(() => {
        // two levels of preventing subsequent queries needed here -
        // one for if a call has gone out but the state is not yet populated (lockApiCalls)
        // and another if all the api calls have gone out but states are in process of being populated (userPageStateIsEmpty)
        if (lockApiCalls.current === true) return;
        lockApiCalls.current = true;

        if (isPageStateEmpty()) {
            getUserAsync();
        } else {
            lockApiCalls.current = false;
        }

        if (pageState.merchant?.submerchants && selectedSubmerchant) {
            setSelectedSubmerchant(pageState.merchant.submerchants.find(s => s.orgId === selectedSubmerchant.orgId));
        }

        if (pageState.merchant?.emailAddresses && selectedEmailAddress) {
            setSelectedEmailAddress(pageState.merchant.emailAddresses.find(e => e.emailAddress === selectedEmailAddress.emailAddress));
        }
    }, [pageState])

    useEffect(() => {
        if (userId) {
            // force the state to fire into the associated useEffect regardless of the data 
            setPageState(prevState => ({
                ...prevState,
                user: null,
                account: null,
                invoiceAccount: null,
                merchant: null,
            }));
        }
    }, [userId]);

    async function onClickCreateInvoice(dateRange: DateRange) {
        if (userId) {
            setIsCreatingInvoice(true);
            await RaiseInvoiceAsync(userId, dateRange.startDate, dateRange.endDate);
            setIsCreatingInvoice(false);
        }
    }

    async function recycleApiKey() {
        if (userId) {
            setIsRecylingKey(true);
            await RecyclePrivateApiKey(userId!);
            await getMerchantAndApiKey();
        }
    }

    const [settings, setSettings] = useState<MerchantSettings>();
    const [initialSettings, setInitialSettings] = useState<MerchantSettings>();

    const handleSettingsChange = (event: CustomEvent) => {
        const element = event.target as HTMLInputElement;
        const name = element.name;
        const newValue = event.detail.checked;

        setSettings(prevSettings => ({
            ...prevSettings!,
            [name]: newValue,
        }));
    };

    const handleSettingsSave = async () => {
        await UpdateMerchantSettingsAsync(userId!, settings!);
        getUserAsync();
    };

    const handleSettingsCancel = () => {
        setSettings({ ...initialSettings! });
    };

    const [previewInvoiceItems, setPreviewInvoiceItems] = useState<InvoiceItem[]>();
    async function onClickPreviewInvoice() {
        let items = await PreviewInvoiceAsync(userId!);
        setPreviewInvoiceItems(items);
    }

    const formatDate = (dateString: string) => format(new Date(dateString), 'dd MMM yyyy HH:mm');

    async function removeRole(role: string): Promise<void> {
        try {
            var submerchant = selectedSubmerchant!;
            var account = submerchant.accounts!.find(a => a.accountId === selectedSubMerchantAccount!.accountId);
            account!.roles = account!.roles!.filter(r => r !== role);
            await UpdateSubmerchantAsync(userId!, submerchant);
        } finally {
            setNewRole(undefined);
            setSelectedSubMerchantAccount(undefined);
            await getUserAsync();
        }
    }

    async function addRole(): Promise<void> {
        try {
            var submerchant = selectedSubmerchant!;
            var account = submerchant.accounts!.find(a => a.accountId === selectedSubMerchantAccount!.accountId);
            account!.roles ??= [];
            account!.roles!.push(newRole!);
            await UpdateSubmerchantAsync(userId!, submerchant);
        } finally {
            setNewRole(undefined);
            setSelectedSubMerchantAccount(undefined);
            await getUserAsync();
        }
    }

    async function removeTemplate(template: string): Promise<void> {
        try {
            const updatedEmailAddress = {
                ...selectedEmailAddress!,
                subscribedTemplates: selectedEmailAddress!.subscribedTemplates.filter(t => t !== template)
            };
            const updatedEmailAddresses = pageState.merchant?.emailAddresses?.map(ea =>
                ea.emailAddress === updatedEmailAddress.emailAddress ? updatedEmailAddress : ea
            );
            await UpdateMerchantEmailAddressesAsync(userId!, updatedEmailAddresses!);
        } finally {
            setNewTemplate(undefined);
            await getUserAsync();
        }
    }

    async function addTemplate(): Promise<void> {
        try {
            const updatedEmailAddress = {
                ...selectedEmailAddress!,
                subscribedTemplates: [...selectedEmailAddress!.subscribedTemplates ?? [], newTemplate!]
            };
            const updatedEmailAddresses = pageState.merchant?.emailAddresses?.map(ea =>
                ea.emailAddress === updatedEmailAddress.emailAddress ? updatedEmailAddress : ea
            );
            await UpdateMerchantEmailAddressesAsync(userId!, updatedEmailAddresses!);
        } finally {
            setNewTemplate(undefined);
            await getUserAsync();
        }
    }

    async function addNewEmailAddress() {
        try {
            if (newEmailAddress && newEmailAddress.trim() !== '') {
                const updatedEmailAddresses = [
                    ...pageState.merchant?.emailAddresses ?? [],
                    { emailAddress: newEmailAddress.trim(), subscribedTemplates: [] }
                ];
                await UpdateMerchantEmailAddressesAsync(userId!, updatedEmailAddresses)
                setNewEmailAddress(''); // Clear the input field
            }
        } finally {
            await getUserAsync();
        }
    }

    async function removeEmailAddress(emailToRemove: string) {
        try {
            const updatedEmailAddresses = pageState.merchant?.emailAddresses.filter(ea => ea.emailAddress !== emailToRemove);
            await UpdateMerchantEmailAddressesAsync(userId!, updatedEmailAddresses!);
        } finally {
            await getUserAsync();
        }
    }

    if (!pageState.merchant || !pageState.account) return <PageSpinner />;

    const previewInvoiceModal = <>
        {previewInvoiceItems && <IonModal isOpen={true}>
            <IonContent className="ion-padding">
                {previewInvoiceItems?.map((item, index) => {
                    return (
                        <IonRow className='invoiceBorder' key={item.id}>
                            <IonCol>{formatDate(item?.itemDate.toString())}</IonCol>
                            <IonCol>{item?.description}</IonCol>
                            <IonCol>{item?.amount.toFixed(2)}</IonCol>
                        </IonRow>
                    );
                })}
            </IonContent>
        </IonModal>}
    </>;

    const merchantSettingsCard = <>
        <IonCard style={{ height: '100vh', maxHeight: '500px' }}>
            <IonCardContent>
                <h2 className='adminSubHeadingBlue' style={{ marginLeft: "4px" }}>Merchant Settings</h2>
                {pageState.merchant && <HttpAuditButton entityId={pageState.merchant.id} />}
                {settings && <>
                    <IonItem>
                        <IonLabel style={{ color: '#737373', fontSize: '15px' }} position="floating">Redirect Button Text</IonLabel>
                        <IonInput
                            name="redirectButtonText"
                            value={settings.redirectButtonText}
                            onIonChange={handleSettingsChange}
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel style={{ color: '#737373', fontSize: '15px' }} position="floating">Redirect URL</IonLabel>
                        <IonInput
                            name="redirectURL"
                            value={settings.redirectURL}
                            onIonChange={handleSettingsChange}
                        />
                    </IonItem>
                    <IonItem lines="none">
                        <IonLabel style={{ color: '#737373', fontSize: '15px' }}>Cards Enabled</IonLabel>
                        <IonToggle
                            name="cardsEnabled"
                            checked={settings.cardsEnabled}
                            onIonChange={handleSettingsChange}
                        />
                    </IonItem>
                    <IonItem lines="none">
                        <IonLabel style={{ color: '#737373', fontSize: '15px' }}>Preview Features</IonLabel>
                        <IonToggle
                            name="previewFeatures"
                            checked={settings.previewFeatures}
                            onIonChange={handleSettingsChange}
                        />
                    </IonItem>
                    <IonItem lines="none">
                        <IonLabel style={{ color: '#737373', fontSize: '15px' }}>Do Not Invoice</IonLabel>
                        <IonToggle
                            name="doNotInvoice"
                            checked={settings.doNotInvoice}
                            onIonChange={handleSettingsChange}
                        />
                    </IonItem>
                    <IonButton style={{ width: '100%', borderRadius: '12px', marginTop: '20px' }} color={'secondary'} onClick={handleSettingsSave}>
                        Save
                    </IonButton>
                    <IonButton style={{ width: '100%', borderRadius: '12px' }} color={'primary'} onClick={handleSettingsCancel}>
                        Cancel
                    </IonButton>
                </>}
            </IonCardContent>
        </IonCard>
    </>;

    const apiKeyCard = <>
        <IonCard style={{ height: '100vh', maxHeight: '500px' }}>
            <IonCardContent>
                <h2 className='adminSubHeadingBlue' style={{ marginLeft: "4px" }}>API Tools</h2>
                {pageState?.merchant &&
                    <>
                        <h2 style={{ fontWeight: "bold" }}>API Access</h2>
                        {pageState.apiKey &&
                            <>
                                <p><strong>Public Key: </strong>{pageState.apiKey.id}</p>
                                <p><strong>Private Key: </strong>{pageState.apiKey.key}</p>
                            </>
                        }
                        <ConfirmationButton
                            buttonLabel="Recycle API Key"
                            alertHeader="Recycle API Key"
                            alertMessage="Warning: recycling the API key will immediately stop the old key working"
                            onConfirm={recycleApiKey}
                            isLoading={isRecyclingKey} />
                    </>}
            </IonCardContent>
        </IonCard>
    </>;

    const merchantInvoiceCard = <>
        <IonCard style={{ height: '100vh', maxHeight: '500px' }}>
            <IonCardContent>
                <h2 className='adminSubHeadingBlue' style={{ marginLeft: "4px" }}>Invoice Tools</h2>
                {pageState?.merchant &&
                    <>
                        <CreateInvoiceControl
                            title="Create Transaction Invoice"
                            buttonLabel="Create Transaction Invoice"
                            alertHeader="Create Transaction Invoice"
                            onConfirm={onClickCreateInvoice}
                            isLoading={isCreatingInvoice} />
                        <br />< br />
                        <IonButton onClick={onClickPreviewInvoice}>
                            Preview Payments Invoice
                        </IonButton>
                    </>
                }
            </IonCardContent>
        </IonCard>
    </>;

    const submerchantCard = <>
        <IonCard style={{ height: '100vh', maxHeight: '500px', display: 'flex' }}>
            <IonCardContent style={{ overflowY: 'auto', width: '100%' }}>
                <h2 className='adminSubHeadingBlue' style={{ marginLeft: "4px" }}>Submerchants</h2>
                <table style={{ width: "100%" }}>
                    <thead>
                        <tr>
                            <th style={{ textAlign: 'left', padding: '8px', borderBottom: '1px solid #ddd' }}>Name</th>
                            <th style={{ textAlign: 'left', padding: '8px', borderBottom: '1px solid #ddd' }}>Status</th>
                            <th style={{ textAlign: 'left', padding: '8px', borderBottom: '1px solid #ddd' }}>Accounts</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pageState?.merchant?.submerchants?.map((submerchant) => (
                            <tr key={submerchant.name}>
                                <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{submerchant.name}</td>
                                <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{submerchant.status}</td>
                                <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                                    <IonButton onClick={() => setSelectedSubmerchant(submerchant)}>View</IonButton>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </IonCardContent>
        </IonCard>
    </>;

    const submerchantAccountsModal = <>
        <IonModal isOpen={!!selectedSubmerchant} onDidDismiss={() => setSelectedSubmerchant(undefined)}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Submerchant Accounts</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={() => setSelectedSubmerchant(undefined)}>Close</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th style={{ textAlign: 'left', padding: '8px', borderBottom: '1px solid #ddd' }}>Account ID</th>
                            <th style={{ textAlign: 'left', padding: '8px', borderBottom: '1px solid #ddd' }}>Roles</th>
                        </tr>
                    </thead>
                    <tbody>
                        {selectedSubmerchant?.accounts?.map((account) => (
                            <tr key={account.accountId}>
                                <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                                    <IonRouterLink href={`/users/${account.accountId}`} target="_blank">
                                        {account.accountId}
                                    </IonRouterLink>
                                </td>
                                <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                                    {account.roles?.join(', ')}
                                </td>
                                <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                                    <IonButton onClick={() => setSelectedSubMerchantAccount(account)}>Edit</IonButton>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </IonContent>
            {selectedSubMerchantAccount && <IonFooter style={{ padding: "15px" }}>
                <IonList>
                    {selectedSubMerchantAccount.roles?.map((role) => (
                        <IonItem>
                            <IonLabel>{role}</IonLabel>
                            <IonButton onClick={() => removeRole(role)}>Remove</IonButton>
                        </IonItem>
                    ))}
                </IonList>
                <IonInput placeholder='Type new role name here' value={newRole} onIonChange={(e) => setNewRole(e.detail.value!)} />
                <IonButton onClick={addRole}>Add</IonButton>
            </IonFooter>}
        </IonModal>
    </>;

    const emailAddressesCard = <>
        <IonCard style={{ height: '100vh', maxHeight: '500px', display: 'flex' }}>
            <IonCardContent style={{ overflowY: 'auto', width: '100%' }}>
                <h2 className='adminSubHeadingBlue' style={{ marginLeft: "4px" }}>Email Addresses</h2>
                <IonItem>
                    <IonInput
                        placeholder="New email address"
                        type="email"
                        value={newEmailAddress}
                        onIonChange={e => setNewEmailAddress(e.detail.value!)}
                    />
                    <IonButton onClick={addNewEmailAddress}>Add</IonButton>
                </IonItem>
                <table style={{ width: "100%" }}>
                    <thead>
                        <tr>
                            <th style={{ textAlign: 'left', padding: '8px', borderBottom: '1px solid #ddd' }}>Email Address</th>
                            <th style={{ textAlign: 'left', padding: '8px', borderBottom: '1px solid #ddd' }}>Subscribed Templates</th>
                            <th style={{ textAlign: 'left', padding: '8px', borderBottom: '1px solid #ddd' }}>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pageState.merchant?.emailAddresses?.map((emailAddress) => (
                            <tr key={emailAddress.emailAddress}>
                                <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{emailAddress.emailAddress}</td>
                                <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{emailAddress.subscribedTemplates?.join(', ')}</td>
                                <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                                    <IonButton onClick={() => setSelectedEmailAddress(emailAddress)}>Edit</IonButton>
                                    <IonButton color="danger" onClick={() => removeEmailAddress(emailAddress.emailAddress)}>Remove</IonButton>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </IonCardContent>
        </IonCard>
    </>;

    const emailAddressModal = <>
        <IonModal isOpen={!!selectedEmailAddress} onDidDismiss={() => setSelectedEmailAddress(undefined)}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Edit Email Address Templates</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={() => setSelectedEmailAddress(undefined)}>Close</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <p><strong>Email Address:</strong> {selectedEmailAddress?.emailAddress}</p>
                <IonList>
                    {selectedEmailAddress?.subscribedTemplates?.map((template) => (
                        <IonItem key={template}>
                            <IonLabel>{template}</IonLabel>
                            <IonButton onClick={() => removeTemplate(template)}>Remove</IonButton>
                        </IonItem>
                    ))}
                </IonList>
                <IonItem>
                    <IonInput placeholder='Type new template name here' value={newTemplate} onIonChange={(e) => setNewTemplate(e.detail.value!)} />
                    <IonButton onClick={addTemplate}>Add</IonButton>
                </IonItem>
            </IonContent>
            <IonFooter style={{ paddingLeft: '15px', paddingRight: '15px' }}>
                <IonToolbar>
                    <IonText color="medium" className="ion-text-center">
                        <small>
                            Example templates: InvoiceRaised, SuccessfulTransaction, FailedTransaction, StandingOrderActivated, StandingOrderFailed
                        </small>
                    </IonText>
                </IonToolbar>
            </IonFooter>
        </IonModal>
    </>;

    return <>
        {previewInvoiceModal}
        <IonPage>
            <IonHeader className='ion-no-border'>
                <IonToolbar >
                    <IonButtons slot="start">
                        <IonMenuButton></IonMenuButton>
                    </IonButtons>
                    <IonGrid>
                        <IonRow className='titleRows'>
                            <IonCol size='auto'> <h1 id='HomeTitle'>TrustistTransfer -</h1></IonCol>
                            <IonCol size='auto'> <h1 id='UsersName'>{pageState?.user?.firstName}, {pageState?.user?.lastName}</h1></IonCol>
                        </IonRow>
                    </IonGrid>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen >
                <IonGrid>
                    <IonRow>
                        <IonCol size='12' sizeXl='6'>
                            {merchantSettingsCard}
                        </IonCol>
                        <IonCol size='12' sizeXl='6'>
                            {apiKeyCard}
                        </IonCol>
                    </IonRow>
                    {pageState?.account?.merchantType === "merchant" && <>
                        <IonRow>
                            <IonCol size='12' sizeXl='6'>
                                {submerchantCard}
                            </IonCol>
                            <IonCol size='12' sizeXl='6'>
                                {merchantInvoiceCard}
                            </IonCol>
                            <IonCol size='12' sizeXl='6'>
                                {emailAddressesCard}
                            </IonCol>
                            <IonCol size='12' sizeXl='6'>
                                <IonCard style={{ height: '100vh', maxHeight: '500px', display: 'flex' }}>
                                    <IonCardContent style={{ overflowY: 'auto', width: '100%' }}>
                                        <h2 className='adminSubHeadingBlue' style={{ marginLeft: "4px" }}>Partner</h2>
                                        {<MerchantPartnerTools merchant={pageState.merchant} accountId={pageState.account.id} />}
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </>}
                </IonGrid>
                {selectedSubmerchant && submerchantAccountsModal}
                {selectedEmailAddress && emailAddressModal}
            </IonContent>
        </IonPage>
    </>;
};