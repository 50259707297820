// ConfirmationButton.tsx
import React, { useState } from 'react';
import { IonButton, IonAlert, IonLoading } from '@ionic/react';

interface ConfirmationButtonProps {
    buttonLabel: string;
    alertHeader: string;
    alertMessage: string;
    onConfirm: () => void;
    isLoading?: boolean;
}

const ConfirmationButton: React.FC<ConfirmationButtonProps> = ({ buttonLabel, alertHeader, alertMessage, onConfirm, isLoading = false }) => {
    const [showConfirmationAlert, setShowConfirmationAlert] = useState<boolean>(false);

    return (
        <>
            <IonButton
                style={{ marginLeft: "-2px", marginTop: "10px" }}
                color="secondary"
                size="small"
                onClick={() => setShowConfirmationAlert(true)}
            >
                {buttonLabel}
            </IonButton>

            <IonAlert
                isOpen={showConfirmationAlert}
                onDidDismiss={() => setShowConfirmationAlert(false)}
                header={alertHeader}
                message={alertMessage}
                buttons={[
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => setShowConfirmationAlert(false),
                    },
                    {
                        text: 'Confirm',
                        handler: () => {
                            onConfirm();
                            setShowConfirmationAlert(false);
                        },
                    }
                ]}
            />

            <IonLoading isOpen={isLoading} message={'Please wait...'} />
        </>
    );
};

export default ConfirmationButton;
