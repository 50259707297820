import { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import { IonPage, IonContent } from "@ionic/react";
import PageSpinner from "../controls/PageSpinner";
import { Partner, GetPartnerByUser, Invitation, InvitationStatus, GetPartnerInvitation } from "../../lib/data/PartnerData";
import { SubmitButton, TextButton } from "../controls/Buttons";
import { Routes } from "../../Routes";

interface InvitationViewParams {
    userId: string,
    invitationId: string,
};

export default function InvitiationViewPage() {
    const history = useHistory();
    const { userId, invitationId } = useParams<InvitationViewParams>();
    const [partner, setPartner] = useState<Partner | null>(null);
    const [invitation, setInvitation] = useState<Invitation>();
    const lockApiCalls = useRef(false);

    useEffect(() => {
        async function GetDataAsync() {
            if (!userId || !invitationId || lockApiCalls.current === true) return;
            lockApiCalls.current = true;

            try {
                const partner = await GetPartnerByUser(userId);
                setPartner(partner);

                GetPartnerInvitation(userId, invitationId).then(response => {
                    setInvitation(response);
                });
            }
            finally {
                lockApiCalls.current = false;
            }
        }

        GetDataAsync();
    }, [userId]);

    if (!invitation || !partner) return <PageSpinner />;

    return <>
        <IonPage>
            <IonContent className="ion-padding" fullscreen>
                <div style={{ margin: "80px", marginTop: "40px" }}>
                    <h1>{partner.name}</h1>
                    <h3>Invitation</h3>

                    <label><strong>Client Business Name</strong></label>
                    <p>{invitation.payload.merchantName}</p>

                    <label><strong>Client Contact Email</strong></label>
                    <p>{invitation.payload.merchantEmail}</p>

                    <label><strong>Invitation Status</strong></label>
                    <p>
                        {InvitationStatus[invitation.status]}
                    </p>

                    {invitation?.association?.merchantId && <SubmitButton onClick={() => history.push(Routes.User(invitation.association.accountId))} text="Visit Merchant" showSpinner={false} />}
                </div>
            </IonContent>
        </IonPage>
    </>;
}