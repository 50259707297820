import { IonButton, IonCard, IonCardContent, IonCheckbox, IonContent, IonModal, IonSelect, IonSelectOption } from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { GetOnboardingData, OnboardingData } from "../../lib";
import { CreatePersonAsync, RyftPerson, RyftRequiredPerson } from "../../lib/data/Cards";
import { useParams } from "react-router";

export type CardOnboardingMissingPersonsProps = {
    missingPersons: RyftRequiredPerson[],
    onNewPerson: (person: RyftPerson) => void,
};

interface ViewParams {
    userId: string,
};

export default function CardOnboardingMissingPersons(props: CardOnboardingMissingPersonsProps) {
    const {userId} = useParams<ViewParams>();
    const [showCreate, setShowCreate] = useState<boolean>(false);
    const directorId = useRef<string>();
    const ownerId = useRef<string>();
    const isBusinessContact = useRef<boolean>(false);
    const [onboarding, setOnboarding] = useState<OnboardingData>();

    function modalDismissed() {
        isBusinessContact.current = false;
        ownerId.current = undefined;
        directorId.current = undefined;
        setShowCreate(false);
    }

    useEffect(() => {
        async function getData() {
            if (userId) {
                var onboarding = await GetOnboardingData(userId);
                setOnboarding(onboarding || undefined);
            }
        }

        getData();
    }, [userId]);

    return <>
        <IonCard>
            <IonCardContent>
                <div>
                    <h3 className='detailsTitle'>Missing Persons</h3>

                    <table style={{ width: '100%' }}>
                        <thead>
                            <tr className='tableRowSpacing'>
                                <th style={{ textAlign: 'left' }}><b>Role</b></th>
                                <th style={{ textAlign: 'left' }}><b>Minimum Quantity</b></th>
                                <th><IonButton style={{ width: '90px', borderRadius: '12px', height: '30px', alignItem: 'left' }}
                                    color="secondary"
                                    size="small"
                                    type="submit"
                                    onClick={() => setShowCreate(true)}>
                                    Add New
                                </IonButton></th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.missingPersons && props.missingPersons.map((person, index) => (
                                <tr className='tableRowSpacing' key={"missing" + index}>
                                    <td className='adminBlueDataText'>{person.role}</td>
                                    <td className='adminBlueDataText'>{person.quantity}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </IonCardContent>
        </IonCard>

        {onboarding &&
            <IonModal isOpen={showCreate} onDidDismiss={modalDismissed}>
                <IonContent className="ion-padding">
                    <h3 className="detailsTitle">Create Person</h3>
                    <p style={{ marginTop: '30px', color: '#737373', fontWeight: 'bold' }}>Director:</p>
                    <IonSelect onIonChange={(e) => directorId.current = e.detail.value}>
                        <IonSelectOption value=""></IonSelectOption>
                        {onboarding.businessDetails?.directors?.map((director, index) =>
                            <IonSelectOption value={director.id} key={director.id}>{director.name}</IonSelectOption>
                        )}
                    </IonSelect>
                    <p style={{ color: '#737373', fontWeight: 'bold' }}>Owner:</p>
                    <IonSelect onIonChange={(e) => ownerId.current = e.detail.value}>
                        <IonSelectOption value=""></IonSelectOption>
                        {onboarding.businessDetails?.beneficiaries?.map((owner, index) =>
                            <IonSelectOption value={owner.id} key={owner.id}>{owner.firstName} {owner.lastName}</IonSelectOption>
                        )}
                    </IonSelect>
                    <div style={{ marginTop: '10px', marginBottom: '10px', color: '#737373', fontWeight: 'bold' }}>
                        Is this the Business Contact?<IonCheckbox style={{ float: 'right' }} onIonChange={(e) => isBusinessContact.current = e.detail.checked} />
                    </div>
                    <IonButton style={{ width: '90px', borderRadius: '12px', height: '30px', marginTop: '20px' }}
                        color="secondary"
                        size="small"
                        type="submit"
                        onClick={() => CreatePersonAsync(userId!, ownerId.current!, directorId.current!, isBusinessContact.current!)
                            .then(() => modalDismissed())}>
                        Upload
                    </IonButton>

                </IonContent>
            </IonModal>
        }
    </>;
}