export const GetQuerystringValue = (name: string) : string | null => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.has(name)) {
      return urlParams.get(name);
    } else {
      return null;
    }
  };

  export const AppendQuerystring = (url: string, name: string, value: string) : string => {
    if (url.includes("?")) {
      url += `&${name}=${value}`;
    } else {
      url += `?${name}=${value}`;
    }

    return url;
  }