import { IonPage, IonHeader, IonToolbar, IonTitle, IonCard, IonCardContent, IonContent } from "@ionic/react";
import CreateInvoiceControl from "../controls/invoicing/CreateInvoiceControl";
import { DateRange } from "../controls/DatePickerRange";
import { RunUninvoicedItemsInvoicingAsync } from "../../lib";

export default function SystemToolsPage() {
    async function onClickRunUninvoicedItemsInvoicing(dateRange: DateRange) {
        await RunUninvoicedItemsInvoicingAsync(dateRange.startDate, dateRange.endDate);
    }

    return <>
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>System Tools</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonCard>
                    <IonCardContent>
                        <h2 className='adminSubHeadingBlue' style={{ marginLeft: "4px" }}>Invoice Tools</h2>

                        <CreateInvoiceControl
                            title="Run Uninvoiced Items Invoicing"
                            buttonLabel="Run"
                            alertHeader="Run Uninvoiced Items Invoicing"
                            onConfirm={onClickRunUninvoicedItemsInvoicing}
                            isLoading={false}
                        />
                    </IonCardContent>
                </IonCard>
            </IonContent>
        </IonPage>
    </>
}