import { IonInput } from "@ionic/react";
import { useRef, useState } from "react";
import { ButtonRef, SubmitButton } from "../../controls/Buttons";

export interface InvitationSearchPanelProps {
    onClick: (searchText?: string) => void,
};

export default function PartnerInvitationSearchPanel({ onClick }: InvitationSearchPanelProps) {
    const [searchText, setSearchText] = useState<string>();
    const submitButtonRef = useRef<ButtonRef>(null);

    return <>
        <div style={{ display: "inline-flex", alignItems: "end", width: "100%", marginBottom: "20px" }}>
            <div style={{ marginRight: "50px", width: "80%" }}>
                <label>Search</label>
                <IonInput fill="outline" onIonChange={e => setSearchText(e.detail.value!)} />
            </div>
            <SubmitButton text="Search"
                ref={submitButtonRef}
                onClick={() => {
                    onClick(searchText);
                    submitButtonRef.current?.stopSpinning();
                }} />
        </div>
    </>;
}