import { ApiGet } from '..';

export type Account = {
    id: string,
    merchantId: string,
    merchantType: string,
};

export type InvoiceAccount = {
    name: string,
    mandate: string,
};

export const GetAccountAsync = async (accountId: string) => {
    var apiAccount = await ApiGet(`accounts/${accountId}`);
    return apiAccount?.data;
}

export const GetInvoiceAccountAsync = async (accountId: string): Promise<InvoiceAccount | null> => {
    // var url = 'accountId=${accountId}';
    // if (accountId !== '') url += `accountId=${accountId}&`;
    var response = await ApiGet(`invoiceAccount/${accountId}`);

    if (response.status === 200) {
        return response?.data;
    }
    return null;
}

