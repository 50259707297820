export const msalConfig = {
    auth: {
        clientId: "",
        authority: "https://login.microsoftonline.com/83c6d1a7-0e66-4747-9e52-462e99703de9",
        redirectUri: "",
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    },
    authorities: {
        editProfile: "",
    }
}

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: [
        "openid",
        "profile",
        "offline_access"]
}

export const tokenRequest = {
    scopes: [] as string[]
}

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: "Enter_the_Graph_Endpoint_Here/v1.0/me"
};