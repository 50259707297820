import React, { createContext, useContext, useEffect, useState, ReactNode, useRef } from 'react';
import { MsalProvider } from '@azure/msal-react';
import { IPublicClientApplication } from '@azure/msal-browser';
import { GetMsalInstance } from './Auth';

interface AuthContextProps {
    msalInstance: IPublicClientApplication | null;
    initializationComplete: boolean;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

interface AuthProviderProps {
    children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [msalInstance, setMsalInstance] = useState<IPublicClientApplication | null>(null);
    const [initializationComplete, setInitializationComplete] = useState(false);
    const initialising = useRef(false);

    useEffect(() => {
        const initializeMsalInstance = async () => {
            if (initialising.current === true) return;
            initialising.current = true;

            try {
                console.log('Initializing MSAL instance');
                const instance = await GetMsalInstance();
                console.log('MSAL instance initialized:', instance);
                setMsalInstance(instance);
                setInitializationComplete(true);
            } catch (e) {
                console.error('Error initializing MSAL instance:', e);
            }
        };

        if (!initializationComplete) {
            initializeMsalInstance();
        }
    }, [initializationComplete]);

    return (
        <AuthContext.Provider value={{ msalInstance, initializationComplete }}>
            {msalInstance && initializationComplete ? (
                <MsalProvider instance={msalInstance}>{children}</MsalProvider>
            ) : (
                <p>Loading...</p>
            )}
        </AuthContext.Provider>
    );
};