// src/AppAuth.tsx
import React from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import App from './App';
import { AuthProvider } from './lib/util/AuthProvider';
import LoginHandler from './lib/util/LoginHandler';

const AppAuth: React.FC = () => {
    return (
        <AuthProvider>
            <AuthenticatedTemplate>
                <App />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <LoginHandler />
                <p>Redirecting...</p>
            </UnauthenticatedTemplate>
        </AuthProvider>
    );
};

export default AppAuth;
