import { IonButton, IonCard, IonCardContent, IonCol, IonGrid, IonInput, IonRow, IonSelect, IonSelectOption } from "@ionic/react";
import { RyftAccount } from "../../lib/data/Cards";

export type CardOnboardingBusinessProps = {
    onboarding: RyftAccount,
    setOnboardingValue: (key: string, value: string) => void,
    fieldErrorMessage: (message: string) => {},
    saveChanges: () => {},
};

export default function CardOnboardingBusiness(props: CardOnboardingBusinessProps) {
    const setOnboardingValue = props.setOnboardingValue;
    const onboarding = props.onboarding;
    const fieldErrorMessage = props.fieldErrorMessage;
    const saveChanges = props.saveChanges;

    return <IonCard>
        <IonCardContent>
            <IonGrid style={{ marginLeft: '-10px' }}>
                <IonRow>
                    <IonCol sizeXs='8' sizeSm='7' sizeMd='8' sizeLg='8' sizeXl='8'><h1 className='detailsTitle'>Submitted Details</h1></IonCol>
                    <IonCol sizeXs='2' sizeSm='2' sizeMd='2' sizeLg='3' sizeXl='3'></IonCol>
                </IonRow>
                <IonRow>
                    <IonCol sizeXs='12' sizeSm='12' sizeMd='6' sizeLg='5' sizeXl='5'>
                        <p><strong>Business Name: </strong></p>
                        <IonInput className="adminInputWhite"
                            value={onboarding?.business.name}
                            onIonChange={val => setOnboardingValue('business.name', val.detail.value!)} />
                        {fieldErrorMessage('business.name')}
                        <br></br>

                        <p><strong>Company Type: </strong></p>
                        <IonSelect className="adminInputWhite"
                            value={onboarding?.business.type}
                            onIonChange={val => setOnboardingValue('business.type', val.detail.value!)}>
                            <IonSelectOption value="">-- Select --</IonSelectOption>
                            <IonSelectOption value="Corporation">Corporation</IonSelectOption>
                            <IonSelectOption value="GovernmentEntity">Government Entity</IonSelectOption>
                            <IonSelectOption value="Charity">Charity</IonSelectOption>
                            <IonSelectOption value="LimitedPartnership">Limited Partnership</IonSelectOption>
                            <IonSelectOption value="PrivateCompany">Private Company</IonSelectOption>
                            <IonSelectOption value="PublicCompany">Public Company</IonSelectOption>
                        </IonSelect>
                        {fieldErrorMessage('business.type')}
                        <br></br>

                        <p><strong>Company Number: </strong></p>
                        <IonInput className="adminInputWhite"
                            value={onboarding?.business.registrationNumber}
                            onIonChange={val => setOnboardingValue('business.registrationNumber', val.detail.value!)} />
                        {fieldErrorMessage('business.registrationNumber')}
                        <br></br>

                        <p><strong>Contact Email: </strong></p>
                        <IonInput className="adminInputWhite"
                            value={onboarding?.business.contactEmail}
                            onIonChange={val => setOnboardingValue('business.contactEmail', val.detail.value!)} />
                        {fieldErrorMessage('business.contactEmail')}
                        <br></br>

                        <IonButton
                            style={{ width: '270px', borderRadius: '12px', height: '30px', marginTop: '22px' }}
                            color="secondary"
                            size="small"
                            type="submit"
                            onClick={() => { saveChanges(); }}>
                            Update Details
                        </IonButton>

                    </IonCol>
                    <IonCol sizeXs='12' sizeSm='12' sizeMd='6' sizeLg='7' sizeXl='7'>
                        <p><strong>Address Line One: </strong></p>
                        <IonInput className="adminInputWhite"
                            value={onboarding?.business.registeredAddress.lineOne}
                            onIonChange={val => setOnboardingValue('business.registeredAddress.lineOne', val.detail.value!)} />
                        {fieldErrorMessage('business.registeredAddress.lineOne')}
                        <br></br>

                        <p><strong>Address Line Two: </strong></p>
                        <IonInput className="adminInputWhite"
                            value={onboarding?.business.registeredAddress.lineTwo}
                            onIonChange={val => setOnboardingValue('business.registeredAddress.lineTwo', val.detail.value!)} />
                        {fieldErrorMessage('business.registeredAddress.lineTwo')}
                        <br></br>

                        <p><strong>City: </strong></p>
                        <IonInput className="adminInputWhite"
                            value={onboarding?.business.registeredAddress.city}
                            onIonChange={val => setOnboardingValue('business.registeredAddress.city', val.detail.value!)} />
                        {fieldErrorMessage('business.registeredAddress.city')}
                        <br></br>

                        <p><strong>Region: </strong></p>
                        <IonInput className="adminInputWhite"
                            value={onboarding?.business.registeredAddress.region}
                            onIonChange={val => setOnboardingValue('business.registeredAddress.region', val.detail.value!)} />
                        {fieldErrorMessage('business.registeredAddress.region')}
                        <br></br>

                        <p><strong>Country: </strong></p>
                        <IonInput className="adminInputWhite"
                            value={onboarding?.business.registeredAddress.country}
                            onIonChange={val => setOnboardingValue('business.registeredAddress.country', val.detail.value!)} />
                        {fieldErrorMessage('business.registeredAddress.country')}
                        <br></br>

                        <p><strong>Postal Code: </strong></p>
                        <IonInput className="adminInputWhite"
                            value={onboarding?.business.registeredAddress.postalCode}
                            onIonChange={val => setOnboardingValue('business.registeredAddress.postalCode', val.detail.value!)} />
                        {fieldErrorMessage('business.registeredAddress.postalCode')}
                        <br></br>

                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonCardContent>
    </IonCard>
}