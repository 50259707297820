import PartnerViewPage from "./PartnerViewPage";
import { IonTabs, IonRouterOutlet, IonTabBar, IonTabButton, IonIcon, IonLabel } from "@ionic/react";
import { Route, useParams } from "react-router";
import { Routes } from "../../Routes";
import PageSpinner from "../controls/PageSpinner";
import PartnerInvitationList from "./PartnerInvitationList";
import PartnerInvitation from "./PartnerInvitation";

interface PartnerNavParams {
    userId: string,
};

export default function PartnerNav() {
    const { userId } = useParams<PartnerNavParams>();

    if (!userId) return <PageSpinner />;

    return <>
        <IonTabs>
            <IonRouterOutlet>
                <Route path="/partners/:userId/" component={PartnerViewPage} exact />
                <Route path="/partners/:userId/invitations" component={PartnerInvitationList} exact />
                <Route path="/partners/:userId/invitations/:invitationId" component={PartnerInvitation} />
            </IonRouterOutlet>

            <IonTabBar id="userTabBar" slot="bottom">
                <IonTabButton tab="Home" href={Routes.Partner(userId)}>
                    <IonIcon src="/assets/icon/homeIcon.svg" />
                    <IonLabel className="tabText">Partner Home</IonLabel>
                </IonTabButton>
                <IonTabButton tab="Invitations" href={Routes.PartnerInvitations(userId)}>
                    <IonIcon src="/assets/icon/homeIcon.svg" />
                    <IonLabel className="tabText">Invitations</IonLabel>
                </IonTabButton>
            </IonTabBar>
        </IonTabs>
    </>;
};