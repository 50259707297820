import { IonButton, IonCard, IonCardContent, IonCol, IonGrid, IonInput, IonRow } from "@ionic/react";
import { RyftAccount } from "../../lib/data/Cards";

export type CardOnboardingSoleTraderProps = {
    onboarding: RyftAccount,
    setOnboardingValue: (key: string, value: string) => void,
    fieldErrorMessage: (message: string) => {},
    saveChanges: () => {},
};

export default function CardOnboardingSoleTrader(props: CardOnboardingSoleTraderProps) {
    const setOnboardingValue = props.setOnboardingValue;
    const onboarding = props.onboarding;
    const fieldErrorMessage = props.fieldErrorMessage;
    const saveChanges = props.saveChanges;

    return <IonCard>
        <IonCardContent>
            <IonGrid style={{ marginLeft: '-10px' }}>
                <IonRow>
                    <IonCol sizeXs='8' sizeSm='7' sizeMd='8' sizeLg='8' sizeXl='8'><h1 className='detailsTitle'>Submitted Details</h1></IonCol>
                    <IonCol sizeXs='2' sizeSm='2' sizeMd='2' sizeLg='3' sizeXl='3'></IonCol>
                </IonRow>
                <IonRow>
                    <IonCol sizeXs='12' sizeSm='12' sizeMd='6' sizeLg='5' sizeXl='5'>
                        <p><strong>First Name: </strong></p>
                        <IonInput className="adminInputWhite"
                            value={onboarding?.individual.firstName}
                            onIonChange={val => setOnboardingValue('individual.firstName', val.detail.value!)} />
                        {fieldErrorMessage('individual.firstName')}
                        <br></br>

                        <p><strong>Middle Name: </strong></p>
                        <IonInput className="adminInputWhite"
                            value={onboarding?.individual.middleNames}
                            onIonChange={val => setOnboardingValue('individual.middleNames', val.detail.value!)} />
                        {fieldErrorMessage('individual.middleNames')}
                        <br></br>

                        <p><strong>Last Name: </strong></p>
                        <IonInput className="adminInputWhite"
                            value={onboarding?.individual.lastName}
                            onIonChange={val => setOnboardingValue('individual.lastName', val.detail.value!)} />
                        {fieldErrorMessage('individual.lastName')}
                        <br></br>

                        <p><strong>Date of Birth (yyyy-MM-dd): </strong></p>
                        <IonInput className="adminInputWhite"
                            value={onboarding?.individual.dateOfBirth}
                            onIonChange={val => setOnboardingValue('individual.dateOfBirth', val.detail.value!)} />
                        {fieldErrorMessage('individual.dateOfBirth')}
                        <br></br>

                        <p><strong>Gender (Male/Female): </strong></p>
                        <IonInput className="adminInputWhite"
                            value={onboarding?.individual.gender}
                            onIonChange={val => setOnboardingValue('individual.gender', val.detail.value!)} />
                        {fieldErrorMessage('individual.gender')}
                        <br></br>

                        {/*<p><strong>Nationality: </strong></p>*/}
                        {/*<IonInput className="adminInputWhite"*/}
                        {/*    value={onboarding?.individual.nationalities[0]}*/}
                        {/*    onIonChange={val => setOnboardingValue('individual.nationalities', val.detail.value!)} />*/}
                        {/*{fieldErrorMessage('individual.nationalities')}*/}

                        <IonButton
                            style={{ width: '270px', borderRadius: '12px', height: '30px', marginTop: '22px' }}
                            color="secondary"
                            size="small"
                            type="submit"
                            onClick={() => { saveChanges(); }}>
                            Update Details
                        </IonButton>

                    </IonCol>
                    <IonCol sizeXs='12' sizeSm='12' sizeMd='6' sizeLg='7' sizeXl='7'>
                        <p><strong>Address Line One: </strong></p>
                        <IonInput className="adminInputWhite"
                            value={onboarding?.individual.address.lineOne}
                            onIonChange={val => setOnboardingValue('individual.address.lineOne', val.detail.value!)} />
                        {fieldErrorMessage('individual.address.lineOne')}
                        <br></br>

                        <p><strong>Address Line Two: </strong></p>
                        <IonInput className="adminInputWhite"
                            value={onboarding?.individual.address.lineTwo}
                            onIonChange={val => setOnboardingValue('individual.address.lineTwo', val.detail.value!)} />
                        {fieldErrorMessage('individual.address.lineTwo')}
                        <br></br>

                        <p><strong>City: </strong></p>
                        <IonInput className="adminInputWhite"
                            value={onboarding?.individual.address.city}
                            onIonChange={val => setOnboardingValue('individual.address.city', val.detail.value!)} />
                        {fieldErrorMessage('individual.address.city')}
                        <br></br>

                        <p><strong>Region: </strong></p>
                        <IonInput className="adminInputWhite"
                            value={onboarding?.individual.address.region}
                            onIonChange={val => setOnboardingValue('individual.address.region', val.detail.value!)} />
                        {fieldErrorMessage('individual.address.region')}
                        <br></br>

                        <p><strong>Country: </strong></p>
                        <IonInput className="adminInputWhite"
                            value={onboarding?.individual.address.country}
                            onIonChange={val => setOnboardingValue('individual.address.country', val.detail.value!)} />
                        {fieldErrorMessage('individual.address.country')}
                        <br></br>

                        <p><strong>Postal Code: </strong></p>
                        <IonInput className="adminInputWhite"
                            value={onboarding?.individual.address.postalCode}
                            onIonChange={val => setOnboardingValue('individual.address.postalCode', val.detail.value!)} />
                        {fieldErrorMessage('individual.address.postalCode')}
                        <br></br>

                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonCardContent>
    </IonCard>
}