import { IonButtons, IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonItem, IonList, IonMenuButton, IonPage, IonRow, IonToolbar } from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { GetUserAsync, User } from "../../lib";
import { ListStandingOrders, StandingOrder } from "../../lib/data/StandingOrders";
import StandingOrderModal from "./StandingOrderTransactionsModal";
import { useParams } from "react-router";

interface ViewParams {
    userId: string,
};

export default function RegularPaymentsPage() {
    const { userId } = useParams<ViewParams>();
    const [user, setUser] = useState<User>();
    const lockApiCalls = useRef(false);

    const [standingOrders, setStandingOrders] = useState<StandingOrder[]>();
    const continuation = useRef<string | undefined>();
    const contentRef = useRef<HTMLIonContentElement | null>(null);
    const [selectedItem, setSelectedItem] = useState<StandingOrder | undefined>();

    useEffect(() => {
        if (!userId) return;
        GetUserAsync(userId).then(user => setUser(user!));
        GetStandingOrdersAsync();
    }, [userId]);

    async function GetStandingOrdersAsync() {
        if (lockApiCalls.current === true || !userId) return;
        lockApiCalls.current = true;

        try {
            const response = await ListStandingOrders(userId, continuation.current);

            (continuation?.current && continuation.current > '')
                ? setStandingOrders(prev => [...prev!, ...response.data])
                : setStandingOrders(response.data);

            continuation.current = response.continuation;
        } finally {
            lockApiCalls.current = false;
        }
    }

    function convertFrequencyToString(frequency: number) {
        switch (frequency) {
            case 1:
                return "Daily";
            case 2:
                return "Weekly";
            case 3:
                return "TwiceWeekly";
            case 4:
                return "Monthly";
            case 5:
                return "TwiceMonthly";
            case 6:
                return "Quarterly";
            case 7:
                return "SemiAnnually";
            case 8:
                return "Annually";
            default:
                return "Unknown Frequency";
        }
    }

    const onClickCancelModel = () => {
        setSelectedItem(undefined);
        
    };

    return <>
        <IonPage>
            <IonToolbar >
                <IonButtons slot="start">
                    <IonMenuButton></IonMenuButton>
                </IonButtons>
                <IonGrid>
                    <IonRow className='subscriptionTitleRows'>
                        <IonCol size='auto'>
                            <h1 id='transactionsMainTitle'>Standing Orders -</h1>
                        </IonCol>
                        <IonCol size='auto'>
                            <h1 id='transactionsUsersName'> {user?.firstName}, {user?.lastName}</h1>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonToolbar>
            <IonContent className='paymentHistoryBlock' ref={contentRef} scrollEvents={true}>
                <IonCard className='transactionGrid'>
                    <IonCardContent>
                        <IonGrid fixed>
                            <IonRow>
                                <IonCol><h1 id='transactionsSubTitle'>Transaction History</h1></IonCol>
                            </IonRow>
                            <IonList>
                                {standingOrders?.map((item, index) => (
                                    <IonItem className='listItem' id={`payment_${item.id}`} key={item.id} onClick={() => setSelectedItem(item)}>
                                        <IonRow style={{ width: '100%' }}>
                                            <IonCol size="5">
                                                <div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                                                    <p style={{ color: '#003466' }}>
                                                        <b>{item.payload.payerName}</b><br />
                                                        {item.payload.payerBusinessName && <b>{item.payload.payerBusinessName}<br/></b>}
                                                        {item.id}
                                                    </p>
                                                </div>
                                            </IonCol>
                                            <IonCol size="4">
                                                <p style={{ color: '#003466' }}><b>Frequency: </b>{item.payload.frequency !== undefined
                                                    ? <span style={{ fontWeight: 500 }}>{convertFrequencyToString(item.payload.frequency)}</span>
                                                    : "Unknown Frequency"}
                                                    <br />
                                                    <b>Start Date:</b> {item.payload.startDate}<br />
                                                    <b>End Date:</b> {item.payload.endDate ? item.payload.endDate : "N/A"}</p>
                                            </IonCol>
                                            <IonCol size="3">

                                                <p style={{ color: '#003466', textAlign: 'end', marginRight: '20px' }}>
                                                    <b>Amount:</b> £{item.payload.amount}
                                                </p>
                                                {item.payload.numberOfPayments ? (
                                                    <><p style={{ color: '#003466', textAlign: 'end', marginRight: '20px' }}>
                                                        <span style={{ fontWeight: 'bold' }}>Number of Payments:</span>
                                                        {' '}
                                                        <span style={{ fontWeight: '500' }}>{item.payload.numberOfPayments}</span></p>
                                                    </>
                                                ) : (
                                                    <><p style={{ color: '#003466', textAlign: 'end', marginRight: '20px' }}><span style={{ fontWeight: 'bold' }}>Number of Payments: </span><span style={{ fontWeight: '500' }}>Ongoing</span></p></>
                                                )}

                                            </IonCol>
                                        </IonRow>
                                    </IonItem>
                                ))}
                            </IonList>
                        </IonGrid>
                    </IonCardContent>
                </IonCard>
                {userId && selectedItem && <StandingOrderModal userId={userId} item={selectedItem} onClosed={onClickCancelModel} />}
            </IonContent>
        </IonPage>
    </>;
}