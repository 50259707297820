import { IonPage, IonContent, IonInfiniteScroll, IonInfiniteScrollContent } from "@ionic/react";
import { useState, useRef, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { Routes } from "../../Routes";
import { GetPartnerByUser, Invitation, InvitationStatus, ListPartnerInvitationsAsync, Partner } from "../../lib/data/PartnerData";
import { LinkButton } from "../controls/Buttons";
import PartnerInvitationSearchPanel from "./controls/PartnerInvitationSearch";
import PageSpinner from "../controls/PageSpinner";

interface InvitationListParams {
    userId: string,
};

export default function PartnerInvitationList() {
    const { userId } = useParams<InvitationListParams>();
    const history = useHistory();
    const [continuation, setContinuation] = useState<string | undefined>();
    const [invitations, setInvitations] = useState<Invitation[]>();
    const [partner, setPartner] = useState<Partner | null>(null);
    const lockApiCalls = useRef(false);

    useEffect(() => { RetrieveData(); }, [userId]);

    async function RetrieveData(searchText?: string) {
        if (!userId || lockApiCalls.current === true) return;
        lockApiCalls.current = true;

        try {
            const partner = await GetPartnerByUser(userId);
            setPartner(partner);
            
            const result = await ListPartnerInvitationsAsync(userId, searchText, continuation);

            if (continuation && invitations) setInvitations(prev => [...prev!, ...result.data]);
            else setInvitations(result.data);

            setContinuation(result.continuation);
        }
        finally {
            lockApiCalls.current = false;
        }
    }

    if (!userId || !partner) return <PageSpinner />;

    return <>
        <IonPage>
            <IonContent>
                <div style={{ margin: "80px", marginTop: "40px" }}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "end", justifyContent: "space-between", marginBottom: "20px" }}>
                        <h1>
                            {partner.name}
                        </h1>
                    </div>
                    <PartnerInvitationSearchPanel onClick={payload => RetrieveData(payload)} />
                    <table style={{ width: "100%" }}>
                        <thead>
                            <tr>
                                <th><strong>Name/Email</strong></th>
                                <th><strong>Invitation Status</strong></th>
                            </tr>
                            <tr style={{ lineHeight: "10px" }}><th>&nbsp;</th></tr>
                        </thead>
                        <tbody>
                            {invitations?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <LinkButton onClick={() => history.push(Routes.PartnerInvitation(userId, item.id))} color="secondary" style={{ marginBottom: "-5px" }}>
                                                {item.payload.merchantName}
                                            </LinkButton>
                                            <br />
                                            {item.payload.merchantEmail}
                                        </td>
                                        <td style={{ color: item.status === InvitationStatus.Completed ? "#17DA36" : "#FFA700" }}>
                                            {InvitationStatus[item.status]}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <IonInfiniteScroll threshold="200px" onIonInfinite={() => RetrieveData()}>
                        <IonInfiniteScrollContent loadingSpinner="bubbles" loadingText="Loading...">
                        </IonInfiniteScrollContent>
                    </IonInfiniteScroll>
                </div>
            </IonContent>
        </IonPage>
    </>;
}