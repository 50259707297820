import { useEffect, useRef, useState } from 'react';
import { ApprovePartnerAsync, GetPartnerApiKeys, GetPartnerByUser, Partner, PartnerApiKeys, PartnerStatus, RecyclePartnerApiKeysAsync } from '../../lib/data/PartnerData';
import PageSpinner from '../controls/PageSpinner';
import { IonPage, IonContent, IonInput } from '@ionic/react';
import { useParams } from 'react-router';
import { ButtonRef, SubmitButton } from '../controls/Buttons';
import { get } from 'lodash';

interface PartnerViewParams {
    userId: string,
};

export default function PartnerViewPage() {
    const { userId } = useParams<PartnerViewParams>();
    const lockApiCalls = useRef(false);
    const [partner, setPartner] = useState<Partner | null>(null);
    const [apiKeys, setApiKeys] = useState<PartnerApiKeys | null | undefined>(undefined);
    const approveButtonRef = useRef<ButtonRef>(null);

    useEffect(() => { getPartnerAsync(); }, [userId]);

    if (!partner) return <PageSpinner />;

    async function getPartnerAsync() {
        if (lockApiCalls.current === true) return;
        lockApiCalls.current = true;

        try {
            if (userId) {
                const partner = await GetPartnerByUser(userId);
                setPartner(partner);

                if (partner.status === PartnerStatus.Approved) {
                    // Get the API keys
                    const apiKeys = await GetPartnerApiKeys(partner.id);
                    setApiKeys(apiKeys);
                }
            }
        }
        finally {
            lockApiCalls.current = false;
        }
    }

    async function approvePartner() {
        if (!partner || lockApiCalls.current === true) return;
        lockApiCalls.current = true;

        try {
            await ApprovePartnerAsync(partner.id);
        }
        finally {
            lockApiCalls.current = false;
            approveButtonRef.current?.stopSpinning();
        }

        getPartnerAsync();
    }

    async function generateApiKeys() {
        if (!partner || lockApiCalls.current === true) return;
        lockApiCalls.current = true;

        try {
            const result = await RecyclePartnerApiKeysAsync(partner.id);
            setApiKeys(result);
        } finally {
            lockApiCalls.current = false;
        }
    }

    return <>
        <IonPage>
            <IonContent>
                <div style={{ margin: "80px", marginTop: "40px" }}>
                    <h1>{partner.name}</h1>
                    <label><strong>Status</strong></label>
                    <p>{PartnerStatus[partner.status]}</p>
                    {partner.status === PartnerStatus.New &&
                        <div>
                            <SubmitButton text="Approve" onClick={approvePartner} ref={approveButtonRef} />
                        </div>}

                    {partner.status === PartnerStatus.Approved && <>
                        <h3>API Keys</h3>
                        {apiKeys === undefined ? <PageSpinner /> :
                            apiKeys ? <>
                                <table>
                                    <tbody>
                                        <tr>
                                            <th style={{ padding: "5px", textAlign: "left" }}>Client ID</th>
                                            <td style={{ padding: "5px", width: "500px", textAlign: "left" }}>
                                                <IonInput fill="outline" disabled value={apiKeys.clientId} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th style={{ padding: "5px" }}>Client Secret</th>
                                            <td style={{ padding: "5px", width: "500px" }}>
                                                <IonInput fill="outline" disabled value={apiKeys.clientSecret} />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </> : <>
                                <p>API keys have not been generated for this partner.</p>
                                <SubmitButton text="Generate API Keys" onClick={generateApiKeys} />
                            </>}
                    </>}
                </div>
            </IonContent>
        </IonPage>
    </>;
}