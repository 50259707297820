import { IonButton, IonCol, IonContent, IonGrid, IonIcon, IonItemGroup, IonModal, IonRow, IonSpinner, IonText } from '@ionic/react';
import { syncOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import "./PaymentDetailsModal.css";
import {PaymentMethod} from '../../lib/data/Payment'


import { format } from 'date-fns';
import { GetPaymentAsync, Payment } from '../../lib';
import HttpAuditButton from '../controls/HttpAuditButton';

export interface PaymentDetailsParams {
    payment: Payment | undefined,
    onClosed(): void
}

export default function PaymentDetailsModal(props: PaymentDetailsParams) {

    const [selectedPayment, setSelectedPayment] = useState<Payment>();
    const [showSpinner, setShowSpinner] = useState<boolean>(false);

    const onClickRefreshPayment = () => {
        setShowSpinner(true);
        getPaymentResult(selectedPayment?.id!);
    };

    const getPaymentResult = (paymentId: string) => GetPaymentAsync(paymentId).then(response => {
        setSelectedPayment(response!);
        setShowSpinner(false);
    });

    useEffect(() => {
        if (!props?.payment?.id) return;
        getPaymentResult(props.payment.id);
    }, [props.payment]);

    const closeModal = () => {
        setSelectedPayment(undefined)
        props.onClosed();
    }
    function PaymentDetailStatusColor(payment: string | undefined) {
        if ((payment === 'PENDING') || (payment === 'PROCESSING') || (payment === 'STARTED')) {
            return 'paymentDetailsStatusPendingOrInProgress'
        }
        else if ((payment === 'SUCCESS') || (payment === 'COMPLETE')) {
            return 'paymentDetailsStatusSuccess'
        }
        else {
            return 'paymentDetailsStatusFailed'
        }
    }
    const FormatDate = (date: Date, dateFormat: string = "d MMM - hh:mmaaa"): string => {
        // const userLocale = navigator.languages && navigator.languages.length
        //                     ? navigator.languages[0]
        //                     : navigator.language;

        //var offset = new Date().getTimezoneOffset();
        //return new Date(date).toLocaleString(userLocale,{hourCycle:"h12"});
        return format(new Date(date), dateFormat);

        //return date;
    }
    let paymentMethod = "Unknown";

    if (selectedPayment !== undefined) {
        switch (selectedPayment.paymentMethod) {
            case PaymentMethod.NotAttempted:
                paymentMethod = "Not Attempted";
                break;
            case PaymentMethod.OpenBanking:
                paymentMethod = "Bank Transfer";
                break;
            case PaymentMethod.Card:
                paymentMethod = "Card Payment";
                break;
        }
    }
    return <>
        <IonModal className='paymentDetailsModal' backdropDismiss={false} isOpen={typeof selectedPayment !== "undefined"}>
            <IonContent>
                <IonGrid class='selectedPaymentGrid'>

                    <IonRow>
                        <IonCol size='2'></IonCol>
                        <IonCol size='8'><div className='ion-text-center'>{showSpinner && <IonSpinner></IonSpinner>}</div></IonCol>
                        <IonCol size='2'>
                            <IonIcon
                                className='refreshIcon'
                                icon={syncOutline}
                                size='large'
                                onClick={onClickRefreshPayment}>
                            </IonIcon>
                        </IonCol>
                    </IonRow>
                    <IonItemGroup hidden={showSpinner}>
                        <IonRow>
                            <IonCol>
                                <IonText style={{ color: '#003466', textAlign: 'center', }}>
                                    <h1 className='selectedPaymentValue'><b><span className='selectedPaymentPoundSign'>£</span>{selectedPayment?.amount}</b></h1>
                                </IonText>
                                {selectedPayment && <HttpAuditButton entityId={selectedPayment.id} />}
                            </IonCol>
                        </IonRow>
                        <IonRow className='selectedPayment'>
                            <IonCol size='1'></IonCol>
                            <IonCol size='10'>
                                <IonText style={{ color: '#003466', textAlign: 'left' }}>
                                    <p className='selectedPaymentText'>{selectedPayment ? (selectedPayment.clientDateCreated ? selectedPayment.clientDateCreated && new Date(selectedPayment.clientDateCreated).toLocaleString("en-GB") : selectedPayment.created.toLocaleString('en-GB')) : ''}</p>
                                </IonText>
                            </IonCol>
                            <IonCol size='1'></IonCol>
                        </IonRow>
                        <IonRow hidden={!selectedPayment?.orgName} className='selectedPayment'>
                            <IonCol size='1'></IonCol>
                            <IonCol size='10'>
                                <IonText style={{ color: '#003466', textAlign: 'left' }}>
                                    <h5 className='selectedPaymentText'><b>Subaccount: </b>{selectedPayment?.orgName}</h5>
                                </IonText>
                            </IonCol>
                            <IonCol size='1'></IonCol>
                        </IonRow>

                        <IonRow className='selectedPayment'>
                            <IonCol size='1'></IonCol>
                            <IonCol size='10'>
                                <IonText style={{ color: '#003466', textAlign: 'left' }}>
                                    <h5 className='selectedPaymentText'><b>Transaction ID: </b>{selectedPayment?.reference}</h5>
                                </IonText>
                            </IonCol>
                            <IonCol size='1'></IonCol>
                        </IonRow>
                        <IonRow hidden={!selectedPayment?.payerName} className='selectedPayment'>
                            <IonCol size='1'></IonCol>
                            <IonCol size='10'>
                                <IonText style={{ color: '#003466', textAlign: 'left' }}>
                                    <h5 className='selectedPaymentText'><b>Customer Name: </b>{selectedPayment?.payerName}</h5>
                                </IonText>
                            </IonCol>
                            <IonCol size='1'></IonCol>
                        </IonRow>
                        <IonRow hidden={!selectedPayment?.customerDetails} className='selectedPayment'>
                            <IonCol size='1'></IonCol>
                            <IonCol size='10'>
                                <IonText style={{ color: '#003466', textAlign: 'left' }}>
                                    <h5 className='selectedPaymentText'><b>Customer Details: </b>{selectedPayment?.customerDetails}</h5>
                                </IonText>
                            </IonCol>
                            <IonCol size='1'></IonCol>
                        </IonRow>
                        <IonRow hidden={!selectedPayment?.description} className='selectedPayment'>
                            <IonCol size='1'></IonCol>
                            <IonCol size='10'>
                                <IonText style={{ color: '#003466', textAlign: 'left' }}>
                                    <h5 className='selectedPaymentText'><b>Description:</b> {selectedPayment?.description}</h5>
                                </IonText>
                            </IonCol>
                            <IonCol size='1'></IonCol>
                        </IonRow>
                        <IonRow className='selectedPayment'>
                            <IonCol size='1'></IonCol>
                            <IonCol size='10'>
                                <IonText style={{ color: '#003466', textAlign: 'left' }}>
                                    <h5 className='selectedPaymentText'>
                                        <b>Payment Type: </b>
                                        {paymentMethod} </h5>
                                </IonText>
                            </IonCol>
                            <IonCol size='1'></IonCol>
                        </IonRow>
                        {selectedPayment?.message && selectedPayment.message > '' && (
                            <IonRow className='selectedPayment'>
                                <IonCol size='1'></IonCol>
                                <IonCol size='10'>
                                    <IonText style={{ color: '#003466', textAlign: 'left' }}>
                                        <h5 className='selectedPaymentText'>
                                            <b>Message:</b>
                                            {selectedPayment?.message}
                                        </h5>
                                    </IonText>
                                </IonCol>
                                <IonCol size='1'></IonCol>
                            </IonRow>
                        )}
                        <IonRow>
                            <IonCol className='selectedPayment'>
                                <IonText style={{ color: '#003466', textAlign: 'center', }}>
                                    <h4 className='statusOfPaymentTitle'><b>Status of Payment:</b></h4>
                                </IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonText style={{ textAlign: 'center', }}>
                                    <h4 className={PaymentDetailStatusColor(selectedPayment?.status)}><b>{selectedPayment?.status}</b></h4>
                                </IonText>
                            </IonCol>
                        </IonRow>
                    </IonItemGroup>
                    <IonRow>
                        <IonCol className="ion-text-center paymentHistoryDetailCloseButton">
                            <IonButton style={{ width: '120px' }} size="small" shape="round" color="secondary" onClick={closeModal}>Close &#187;</IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>

            </IonContent>
        </IonModal>
    </>;
};