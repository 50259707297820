import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useAuth } from './AuthProvider';
import { InteractionStatus } from '@azure/msal-browser';
import { loginRequest } from '../../authConfig';

const LoginHandler: React.FC = () => {
    const { inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const { msalInstance } = useAuth();
    const [loginAttempted, setLoginAttempted] = useState(false);
    const isAuthenticating = useRef(false);

    const handleLogin = useCallback(async () => {
        if (isAuthenticating.current === true) return;
        isAuthenticating.current = true;

        if (!msalInstance || inProgress !== InteractionStatus.None || isAuthenticated || loginAttempted) {
            console.log('Skipping login due to one of the conditions not being met.');
            return;
        }

        setLoginAttempted(true);

        try {
            console.log('Logging in.');
            await msalInstance.handleRedirectPromise();
            await msalInstance.loginRedirect(loginRequest);
        } catch (e) {
            console.error('Error during loginRedirect:', e);
            setLoginAttempted(false); // Reset loginAttempted if error occurs
        }
    }, [msalInstance, inProgress, isAuthenticated, loginAttempted]);

    useEffect(() => {
        if (inProgress === InteractionStatus.None && !isAuthenticated && !loginAttempted) {
            handleLogin();
        }
    }, [inProgress, isAuthenticated, loginAttempted, handleLogin]);

    return null;
};

export default LoginHandler;